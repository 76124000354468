import { Box } from '@amzn/awsui-components-react/polaris';
import { useParams } from 'react-router';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import DatasetUploadStatus from 'src/pages/plan-manager-page/plan-details-tab/components/DatasetUploadStatus';
import GeneralPlanDetails from 'src/pages/plan-manager-page/plan-details-tab/components/GeneralPlanDetails';
import LastComputeDetails from 'src/pages/plan-manager-page/plan-details-tab/components/LastComputeDetails';

const PlanDetailsTab = (): JSX.Element => {
  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId }, { staleTime: 0 });

  return (
    <div>
      <Box margin={{ top: 'm' }} data-testid="general-plan-details">
        <GeneralPlanDetails batchMetadata={batchMetadata} />
      </Box>
      <Box margin={{ top: 'm' }} data-testid="last-compute-details">
        <LastComputeDetails batchMetadata={batchMetadata} />
      </Box>
      <Box margin={{ top: 'm' }} data-testid="dataset-upload-status">
        <DatasetUploadStatus batchMetadata={batchMetadata} />
      </Box>
    </div>
  );
};

export default PlanDetailsTab;
