import { TFunction } from 'i18next';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';
import { generateS3DownloadUrl } from 'src/utils/s3';

interface StructuredErrorInfo {
  message: string;
  downloadLink: string | null;
}

interface DownloadFileOptions {
  fileName?: string;
  generateFreshUrl?: boolean;
}

const getStructuredErrorInfo = (errorMessage: string): StructuredErrorInfo => {
  try {
    const { errorMessageBody, errorMessageLocation } = JSON.parse(errorMessage);
    return { message: errorMessageBody ?? null, downloadLink: errorMessageLocation ?? null };
  } catch (_) {
    return { message: errorMessage, downloadLink: null };
  }
};

const getApiErrorMessage = (e: any, t: TFunction<'translation', undefined>): string => {
  const message = e.response?.data?.message || e.message || e.errorMessage || e.toString();
  switch (message) {
    case 'Network Error':
      return t('network_issue_error');
    case 'Endpoint request timed out':
      return t('request_timeout_error');
  }
  return message;
};

export const getApiErrorInfo = (
  error: any,
  t: TFunction<'translation', undefined>,
): StructuredErrorInfo => {
  const errorMessage = getApiErrorMessage(error, t);
  return getStructuredErrorInfo(errorMessage);
};

export const getDatasetErrorInfo = (
  batchMetadata: IBatchMetadataParsed | undefined,
  datasetName: string,
): StructuredErrorInfo => {
  const { datasetLocations } = batchMetadata || {};
  const { datasetNote } = datasetLocations?.find((o) => o.datasetName === datasetName) || {};
  return getStructuredErrorInfo(datasetNote);
};

/**
 * let the browser start downloading a file
 * if no fileName specified the file will be named 'error.csv'
 */
export const downloadFile = async (
  fileUrl: string | undefined,
  feS3UrlGenerationEnabled?: boolean,
  options?: DownloadFileOptions,
) => {
  if (!fileUrl) return;

  let downloadUrl = fileUrl;

  if (feS3UrlGenerationEnabled && options?.generateFreshUrl !== false) {
    const freshUrl = await generateS3DownloadUrl(fileUrl);
    if (!freshUrl) return;
    downloadUrl = freshUrl;
  }

  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = options?.fileName || 'error.csv';
  link.click();
};
