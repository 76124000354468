import { AiOutlineFullscreen } from 'react-icons/ai';
import { BiExport, BiRefresh } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dispatch, RefObject, SetStateAction, useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { uniqueId } from 'lodash';
import { DataGridActionButtonGroups } from 'src/pages/commons/plan-views/DatasetGridContainer';
import { useTriggerExtractOutput } from 'src/pages/commons/compute-helpers/useTriggerPlanCompute';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { SelectChangeEvent } from 'src/common/EventType';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';
import { downloadFile } from 'src/utils/errorMessage';
import { useSsrmOutputDataset } from 'src/pages/plan-manager-page/plan-output-tab/components/useSsrmOutputDataset';
import { useDatasetExportLink } from 'src/api/query/useDatasetExportLink';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';

interface UseSsrmOutputGridParamsProps {
  gridRef: RefObject<AgGridReact>;
  currentDataset: SupportedDatasetType;
  setIsFullScreen: Dispatch<SetStateAction<boolean>>;
  setCurrentDataset: (currentDataset: SupportedDatasetType) => void;
}

export const useSsrmOutputGridParams = ({
  gridRef,
  currentDataset,
  setIsFullScreen,
  setCurrentDataset,
}: UseSsrmOutputGridParamsProps) => {
  const { t } = useTranslation();
  const { batchId } = useParams();
  const { data: batchMetadata } = useBatchMetadata({ batchId });
  const { data: featureFlags } = useFeatureFlags();

  const { serverSideDatasource, columnDefs, datasetRowCount, datasetRequest } =
    useSsrmOutputDataset(currentDataset);

  const { refetch: getDatasetExportLink } = useDatasetExportLink(datasetRequest);

  const { triggerExtractOutput, isExtractOutputSupported } = useTriggerExtractOutput(
    batchMetadata?.costType,
  );

  const actionButtonGroups = useMemo(() => {
    const handleClickExport = async () => {
      const { data: datasetExportLink } = await getDatasetExportLink();
      downloadFile(datasetExportLink, featureFlags?.feS3UrlGeneration ?? false, {
        fileName: currentDataset,
        generateFreshUrl: false,
      });
    };

    const handleClickRefresh = () => {
      if (!batchId) return;
      if (isExtractOutputSupported) triggerExtractOutput({ batchId });
      else gridRef.current?.api.refreshServerSide({ purge: true });
    };

    const handleClickExpand = () => {
      setIsFullScreen(true);
    };

    const isExceedExportLimit = datasetRowCount && datasetRowCount >= 999999;

    const buttonGroups: DataGridActionButtonGroups = [
      [
        {
          icon: BiExport,
          text: t('export'),
          onClick: handleClickExport,
          disabled: isExceedExportLimit ? t('dataset_size_exceed_export_limit') : false,
        },
        { icon: BiRefresh, text: t('refresh'), onClick: handleClickRefresh },
      ],
      [{ icon: AiOutlineFullscreen, text: t('expand'), onClick: handleClickExpand }],
    ];

    return buttonGroups;
  }, [
    batchId,
    currentDataset,
    datasetRowCount,
    featureFlags?.feS3UrlGeneration,
    getDatasetExportLink,
    gridRef,
    isExtractOutputSupported,
    setIsFullScreen,
    t,
    triggerExtractOutput,
  ]);

  const getRowId = useCallback(() => uniqueId(), []);

  const handleDatasetSelected = (e: SelectChangeEvent) => {
    const newDataset = e.detail.selectedOption.value as SupportedDatasetType;
    if (newDataset !== currentDataset) setCurrentDataset(newDataset);
  };

  return {
    actionButtonGroups,
    getRowId,
    handleDatasetSelected,
    serverSideDatasource,
    columnDefs,
  };
};
