import { StatusIndicator } from '@amzn/awsui-components-react/polaris';
import {
  CellStyleFunc,
  ColDef,
  ITooltipParams,
  NewValueParams,
  ValueGetterFunc,
  ValueSetterFunc,
} from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { agRowInlineEditsUpdate } from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/inputAgGridInlineEditHelper';
import {
  IRowValidationCellData,
  IRowValidationRowWithId,
  IValidationRowTransformer,
} from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/inputAgGridRowValidationHelper';
import {
  isErrorRow,
  navigateToNextErrorRowIds,
} from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/inputAgGridErrorNavigationHelper';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import { useDarkModeContext } from 'src/hooks/useDarkModeContext';

interface UseInputAgGridDefaultColDefProps {
  validationRowTransformer?: IValidationRowTransformer | null;
  gridRef: RefObject<AgGridReact>;
  validHeaders: string[] | undefined;
  setInlineEdits: any;
  inlineEditsDisabled: boolean;
  savedUnsolvedErrorRowIds?: Set<number>;
  setSavedUnsolvedErrorRowIds?: Dispatch<SetStateAction<Set<number>>>;
}

export const useInputAgGridDefaultColDef = ({
  validationRowTransformer,
  gridRef,
  validHeaders,
  setInlineEdits,
  inlineEditsDisabled,
  savedUnsolvedErrorRowIds,
  setSavedUnsolvedErrorRowIds,
}: UseInputAgGridDefaultColDefProps) => {
  const { isDarkMode } = useDarkModeContext();

  const { data: featureFlags } = useFeatureFlags();

  const onCellValueChanged = (params: NewValueParams) => {
    const data: IRowValidationRowWithId = params.data;

    if (!validationRowTransformer) return;

    const oldData = cloneDeep(data);
    if (params?.colDef?.field ?? '' in oldData) {
      oldData[params.colDef.field!].value = params.oldValue;
    }

    const formattedRow = validationRowTransformer(data);

    const inlineEditsUpdater = agRowInlineEditsUpdate(formattedRow, validHeaders, oldData);
    setInlineEdits(inlineEditsUpdater);

    params.api.applyTransaction({ update: [formattedRow] });
    params.api.refreshCells({ rowNodes: [params.node!], force: true });

    /** if this cell value change cleared a saved & unsolved error row */
    if (savedUnsolvedErrorRowIds?.has(formattedRow.id.value) && !isErrorRow(formattedRow)) {
      /** update savedUnsolvedErrorRowIds */
      const newErrorRowIds = new Set(savedUnsolvedErrorRowIds);
      newErrorRowIds.delete(formattedRow.id.value);
      setSavedUnsolvedErrorRowIds?.(newErrorRowIds);

      /** navigate to the next error row */
      navigateToNextErrorRowIds(gridRef, newErrorRowIds);
    }
  };

  const valueGetter: ValueGetterFunc<Record<string, IRowValidationCellData>, string> = (params) => {
    const { field } = params.colDef;
    if (!field) return null;
    return params.data?.[field]?.value ?? null;
  };

  const valueSetter: ValueSetterFunc<Record<string, IRowValidationCellData>, string> = (params) => {
    const data = params.data;
    const { field } = params.colDef;

    const newValue = typeof params.newValue === 'string' ? params.newValue.trim() || null : null;

    if (params.oldValue === newValue || !field) return false;

    const newData: IRowValidationCellData = {
      value: newValue,
      original: data[field].original,
      lastValidationSuccess: true,
      ...(featureFlags?.usingValidationFramework && {
        lastValidationNoWarning: true,
      }),
      validationErrors: [],
    };

    data[field] = newData;

    params.api.applyTransaction({ update: [data] });

    return true;
  };

  const cellStyle: CellStyleFunc = (params) => {
    const data = params.data?.[params.colDef.field!];

    if (!data) return { backgroundColor: 'transparent' };

    if (!data.lastValidationSuccess) {
      return { backgroundColor: isDarkMode ? 'purple' : 'mediumpurple' };
    }

    if (featureFlags?.usingValidationFramework && !data.lastValidationNoWarning) {
      return { backgroundColor: isDarkMode ? 'darkorange' : 'orange' };
    }

    if (data.value !== data.original) {
      return { backgroundColor: isDarkMode ? 'darkgray' : 'lightyellow' };
    }

    return { backgroundColor: 'transparent' };
  };

  const tooltipComponent = (params: any) => {
    const data = params.data?.[params.column?.colDef?.field];
    const messageType = !data?.lastValidationSuccess ? 'error' : 'warning';

    return (
      <div
        style={{
          backgroundColor: messageType === 'error' ? 'black' : 'beige',
          padding: '8px',
          border: '1px solid lightgray',
        }}
      >
        {params.value.map((message: string) => (
          <div key={message} style={{ padding: '2px' }}>
            <StatusIndicator type={messageType}>{message}</StatusIndicator>
          </div>
        ))}
      </div>
    );
  };

  const tooltipValueGetter: ((params: ITooltipParams) => string) | undefined = (params) => {
    try {
      const data = params.data[(params.colDef as ColDef).field!];
      if (
        !data.lastValidationSuccess ||
        (featureFlags?.usingValidationFramework && !data.lastValidationNoWarning)
      ) {
        return data.validationErrors;
      }
    } catch (_) {
      // do nothing
    }
    return '';
  };

  const defaultColDef: ColDef = {
    editable: !inlineEditsDisabled,
    onCellValueChanged,
    valueGetter,
    valueSetter,
    cellStyle,
    tooltipComponent,
    tooltipValueGetter,
  };

  return { defaultColDef };
};
