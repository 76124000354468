import { BatchDetails, BatchesList } from '@amzn/fox-den-cost-planning-lambda';
import { cloneDeep, get, isArray, isEmpty, isNumber, max, min } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBatchMetadataMutation } from 'src/api/mutation/useBatchMetadataMutation';
import { fetchBatchMetadata, useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { ListBatchesOptions, useBatchesListParallel } from 'src/api/query/useBatchesList';
import { RequestVariables } from 'src/api/api-utils';
import AgEditableDataGrid from 'src/common/ag-grid/editable-datagrid/AgEditableDataGrid';
import {
  agCellValueFormatter,
  agEditsCloning,
  cellValueFormatter,
  getCellValue,
} from 'src/common/ag-grid/editable-datagrid/AgGridConfigHelper';
import {
  LastModifiedEnum,
  addBatchMetadataToIdMap,
  useDelayedEffect,
} from 'src/common/ag-grid/editable-datagrid/AgHelper';
import { useNotificationContext } from 'src/hooks/useNotificationContext';
import { useUserAlias } from 'src/hooks/useUserAlias';
import { useTriggerPlanLoadBaseline } from 'src/pages/commons/compute-helpers/useTriggerPlanCompute';
import {
  BaselineColumnIds,
  BaselineConfigObjects,
  BaselineDatasourceNames,
} from 'src/pages/plan-manager-page/plan-baseline-tab/BaselineModel';
import { generateColumnConfigUTRCost } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/UTRCostColumnConfigs';
import { getNextExecutionOrder } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/columnConfigBase';
import { generateColumnConfigDepreciation } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/depreciationColumnConfigs';
import { getInputDatasets } from 'src/pages/plan-manager-page/plan-input-tab/planInputConfig';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';
import { BatchStatus, ListPlanFilterType, PlanTypeId } from 'src/utils/planning/planetModel';
import { generateColumnConfigFixedCostConsolidation } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/fixedCostConsolidationColumnConfigs';
import { generateColumnConfigRealEstate } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/realEstateColumnConfigs';
import { generateColumnConfigThreeP } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/3pColumnConfigs';
import { generateColumnConfigCapex } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/capexColumnConfigs';
import { generateColumnConfigOTRWarehouseTransfer } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/otrWarehouseTransferColumnConfigs';
import { generateColumnConfigOTRDomesticInbound } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/otrDomesticInboundColumnConfigs';
import { generateColumnConfigOTRFreightCapitalization } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/otrFreightCapitalizationColumnConfigs';
import { generateColumnConfigOTRSupplyType } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/otrSupplyTypeColumnConfigs';
import { generateColumnConfigSecurityType } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/securityColumnConfigs';
import { generateColumnConfigTopsDownForecastType } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/topsDownForecastColumnConfigs';
import { generateColumnConfigCFConsolidationType } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/cfConsolidationColumnConfig';
import { generateColumnConfigJanitorialType } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/janitorialColumnConfigs';
import { useUnsavedChangesModal } from 'src/pages/commons/plan-actions/useUnsavedChangesModal';

export const getBaselineSourceTypes = (planType: string) => {
  if (planType === PlanTypeId.UTR_COST_HC) {
    return [
      {
        id: BaselineDatasourceNames.PRODUCTIVITY_OUTPUT,
        name: BaselineDatasourceNames.PRODUCTIVITY_OUTPUT,
      },
      {
        id: BaselineDatasourceNames.SNAPSHOT_DATE,
        name: BaselineDatasourceNames.SNAPSHOT_DATE,
      },
    ];
  }

  if (planType === PlanTypeId.CAPEX) {
    return [
      {
        id: BaselineDatasourceNames.CAPEX_PLAN,
        name: BaselineDatasourceNames.CAPEX_PLAN,
      },
    ];
  }

  if (planType === PlanTypeId.THREE_P_TRANSPORTATION) {
    return [
      {
        id: BaselineDatasourceNames.THREE_P_TRANSPORTATION_PLAN,
        name: BaselineDatasourceNames.THREE_P_TRANSPORTATION_PLAN,
      },
    ];
  }

  if (planType === PlanTypeId.DEPRECIATION) {
    return [
      {
        id: BaselineDatasourceNames.CAPEX_PLAN,
        name: BaselineDatasourceNames.CAPEX_PLAN,
      },
      {
        id: BaselineDatasourceNames.DEPRECIATION_PLAN,
        name: BaselineDatasourceNames.DEPRECIATION_PLAN,
      },
    ];
  }

  if (planType === PlanTypeId.REAL_ESTATE) {
    return [
      {
        id: BaselineDatasourceNames.PLAN,
        name: BaselineDatasourceNames.PLAN,
      },
    ];
  }

  if (planType === PlanTypeId.FIXED_COST_CONSOLIDATION) {
    return [
      {
        id: BaselineDatasourceNames.REAL_ESTATE_PLAN,
        name: BaselineDatasourceNames.REAL_ESTATE_PLAN,
      },
      {
        id: BaselineDatasourceNames.DEPRECIATION_PLAN,
        name: BaselineDatasourceNames.DEPRECIATION_PLAN,
      },
    ];
  }

  if (planType === PlanTypeId.OTR_WAREHOUSE_TRANSFER) {
    return [
      {
        id: BaselineDatasourceNames.OTR_WAREHOUSE_TRANSFER_PLAN,
        name: BaselineDatasourceNames.OTR_WAREHOUSE_TRANSFER_PLAN,
      },
    ];
  }

  if (planType === PlanTypeId.OTR_DOMESTIC_INBOUND) {
    return [
      {
        id: BaselineDatasourceNames.OTR_DOMESTIC_INBOUND_PLAN,
        name: BaselineDatasourceNames.OTR_DOMESTIC_INBOUND_PLAN,
      },
    ];
  }

  if (planType === PlanTypeId.OTR_FREIGHT_CAPITALIZATION) {
    return [
      {
        id: BaselineDatasourceNames.OTR_FREIGHT_CAPITALIZATION_PLAN,
        name: BaselineDatasourceNames.OTR_FREIGHT_CAPITALIZATION_PLAN,
      },
    ];
  }

  if (planType === PlanTypeId.OTR_SUPPLY_TYPE) {
    return [
      {
        id: BaselineDatasourceNames.OTR_SUPPLY_TYPE_PLAN,
        name: BaselineDatasourceNames.OTR_SUPPLY_TYPE_PLAN,
      },
    ];
  }

  if (planType === PlanTypeId.SECURITY) {
    return [
      {
        id: BaselineDatasourceNames.SECURITY_PLAN,
        name: BaselineDatasourceNames.SECURITY_PLAN,
      },
    ];
  }

  if (planType === PlanTypeId.TOPS_DOWN_FORECAST) {
    return [
      {
        id: BaselineDatasourceNames.TOPS_DOWN_FORECAST_PLAN,
        name: BaselineDatasourceNames.TOPS_DOWN_FORECAST_PLAN,
      },
    ];
  }

  if (planType === PlanTypeId.CF_CONSOLIDATION) {
    return [
      {
        id: BaselineDatasourceNames.CF_CONSOLIDATION_PLAN,
        name: BaselineDatasourceNames.CF_CONSOLIDATION_PLAN,
      },
    ];
  }

  if (planType === PlanTypeId.JANITORIAL) {
    return [
      {
        id: BaselineDatasourceNames.JANITORIAL_PLAN,
        name: BaselineDatasourceNames.JANITORIAL_PLAN,
      },
    ];
  }

  return [];
};

const CostPlanningBaselineGeneral = () => {
  const { t } = useTranslation();
  const { alias } = useUserAlias();
  const { addNotification } = useNotificationContext();
  const { batchId } = useParams();
  const { data: batchMetadata } = useBatchMetadata({
    batchId,
  });

  const getPlanTypesForFilter = (editingPlanType: string) => {
    const planTypes: string[] = [];

    if (editingPlanType === PlanTypeId.UTR_COST_HC) {
      planTypes.push(PlanTypeId.UTR_COST_HC, PlanTypeId.UTR_PRODUCTIVITY);
    } else if (editingPlanType === PlanTypeId.DEPRECIATION) {
      // Need to push both, as /listBatches needs to find the editing plan (Depreciation)
      // to use its metadata for matching.
      planTypes.push(PlanTypeId.DEPRECIATION, PlanTypeId.CAPEX);
    } else if (editingPlanType === PlanTypeId.FIXED_COST_CONSOLIDATION) {
      planTypes.push(
        PlanTypeId.DEPRECIATION,
        PlanTypeId.REAL_ESTATE,
        PlanTypeId.FIXED_COST_CONSOLIDATION,
      );
    } else {
      planTypes.push(editingPlanType);
    }

    return planTypes;
  };

  const getFilterType = (
    editingPlanType: string,
    sourceType: BaselineDatasourceNames | null = null,
  ) => {
    switch (editingPlanType) {
      case PlanTypeId.DEPRECIATION:
        if (sourceType === BaselineDatasourceNames.CAPEX_PLAN) {
          return ListPlanFilterType.CAPEX_FOR_DEPRECIATION_BASELINE;
        }
      /* falls through */
      case PlanTypeId.REAL_ESTATE:
      case PlanTypeId.CAPEX:
      case PlanTypeId.THREE_P_TRANSPORTATION:
      case PlanTypeId.OTR_WAREHOUSE_TRANSFER:
      case PlanTypeId.OTR_DOMESTIC_INBOUND:
      case PlanTypeId.OTR_FREIGHT_CAPITALIZATION:
      case PlanTypeId.OTR_SUPPLY_TYPE:
      case PlanTypeId.SECURITY:
      case PlanTypeId.TOPS_DOWN_FORECAST:
      case PlanTypeId.CF_CONSOLIDATION:
      case PlanTypeId.JANITORIAL:
        return ListPlanFilterType.R_AND_D_BASELINE;
      case PlanTypeId.FIXED_COST_CONSOLIDATION:
        return ListPlanFilterType.FIXED_COST_CONSOLIDATION_BASELINE;
      // Should change once we have more types of Baselines.
      default:
        return ListPlanFilterType.UTR_BASELINE;
    }
  };

  const sourceTypeToListBatchesPayload = useMemo(() => {
    const sourceTypeToListBatchesPayload: Record<string, RequestVariables<ListBatchesOptions>> = {};
    const planType = batchMetadata?.costType ?? '';
    const sourceTypes = getBaselineSourceTypes(planType);

    sourceTypes.forEach((sourceType) => {
      const costTypeList = getPlanTypesForFilter(planType);
      const filterType = getFilterType(planType, sourceType.id);

      const filterList = {
        businessGroup: [batchMetadata?.businessGroup ?? ''],
        region: [batchMetadata?.region ?? ''],
        subGroup: [batchMetadata?.subGroup ?? ''],
        costType: costTypeList,
        batchStatus: [BatchStatus.ALL],
      };

      sourceTypeToListBatchesPayload[sourceType.id] = {
        filterList,
        filterType,
        editingPlanId: batchId,
      };
    });

    return sourceTypeToListBatchesPayload;
  }, [
    batchId,
    batchMetadata?.businessGroup,
    batchMetadata?.costType,
    batchMetadata?.region,
    batchMetadata?.subGroup,
  ]);

  const listedPlansNested = useBatchesListParallel(Object.values(sourceTypeToListBatchesPayload), {
    disabled: !batchMetadata,
  });

  const sourceTypeToListedPlans: Record<string, BatchesList> = useMemo(
    () =>
      Object.fromEntries(
        listedPlansNested
          .map((result) => get(result, 'data.batches', null))
          // Build entries for source type to listed plans mapping.
          .map((listedPlans, index) => [
            Object.keys(sourceTypeToListBatchesPayload)[index],
            listedPlans,
          ])
          // Need to filter out key/result pairs where API has not yet returned plans (i.e. null).
          .filter(([_, listedPlans]) => !!listedPlans),
      ),
    [sourceTypeToListBatchesPayload, listedPlansNested],
  );

  const havePlansBeenLoaded = useMemo(
    () =>
      Object.keys(sourceTypeToListBatchesPayload).every((sourceType) =>
        Object.hasOwn(sourceTypeToListedPlans, sourceType),
      ),
    [sourceTypeToListBatchesPayload, sourceTypeToListedPlans],
  );

  const [loaded, setLoaded] = useState(false);
  const gridRef = useRef(null as any);
  const [inlineEdits, setInlineEdits] = useState({} as any);
  const [idToBatchMetadataMap, setIdToBatchMetadataMap] = useState<
    Record<string, IBatchMetadataParsed>
  >({});

  const indexedId = useRef(0);

  const initialGridState = {
    gridName: 'Baseline Grid',
    visualType: 'DataGrid',
    gridFramework: 'AgGrid',
    gridLevelEditable: true,
    buttonConfigs: {
      Save: { Enabled: true },
      Add: { Enabled: true },
      Clone: { Enabled: true },
      Delete: { Enabled: true },
      Refresh: { Enabled: true },
      Expand: { Enabled: true },
    },
  };

  const [gridState] = useState(initialGridState);

  const [dataSource, setDataSource] = useState([] as any);

  const fetchBatchDataAndAdd = useCallback(
    (batchId: string) => {
      if (batchId && !idToBatchMetadataMap[batchId]) {
        fetchBatchMetadata({
          batchId,
          requesterIdentity: alias,
        }).then((batchMetadata) => {
          if (batchMetadata) {
            addBatchMetadataToIdMap(batchMetadata, setIdToBatchMetadataMap, false);
          }
        });
      }
    },
    [alias, idToBatchMetadataMap],
  );

  const getAvailablePlanMetadata = (sourceType: string, planId: string) => {
    const planList: BatchDetails[] = sourceTypeToListedPlans[sourceType];
    return isEmpty(planList) ? null : planList.find((plan) => planId === plan.batchId);
  };

  const setInitialData = (newBatchMetadata: IBatchMetadataParsed) => {
    const configList = get(newBatchMetadata, 'baselineConfig.baselineConfigItemList', []);
    if (!configList) {
      return;
    }

    const shouldUpdateDownstreamMap = {
      true: 'Yes',
      false: 'No',
    };

    const datasets = getInputDatasets(newBatchMetadata, t);

    const initialData = configList.map((item: any) => {
      const baselineConfigItemData = {
        [BaselineColumnIds.TAGS]: '',
        [BaselineColumnIds.COUNTRY_LIST]: [],
        [BaselineColumnIds.SUB_GROUP_TYPE_LIST]: [],
        [BaselineColumnIds.BUILDING_STATUS_LIST]: [],
        [BaselineColumnIds.LOCATION_LIST]: [],
        [BaselineColumnIds.METRIC_LIST]: [],
        [BaselineColumnIds.ACCOUNT_LIST]: [],
        [BaselineColumnIds.SOURCE_LOGIC]: '',
        [BaselineColumnIds.SOURCE_PERIOD_LIST]: [],
        [BaselineColumnIds.DESTINATION_PERIOD_LIST]: [],
        [BaselineColumnIds.PERCENTAGE_ADJUSTMENT]: 0,
        [BaselineColumnIds.UPDATE_DATASET_ON_SAVE]:
          shouldUpdateDownstreamMap[
            get(item, 'shouldUpdateDownstream', 'true') as 'true' | 'false'
          ],
        [BaselineColumnIds.MODIFIED_BY]: get(item, 'modifiedBy', ''),
        [BaselineColumnIds.MODIFIED_AT]: get(item, 'modifiedAt', ''),
        [BaselineColumnIds.EXECUTING_ORDER]: get(item, 'executingOrder', 100),
        ...get(item, 'planLogicConfig'),
        ...get(item, 'conditionsConfig'),
        [BaselineColumnIds.METRIC_GROUP_LIST]: get(
          item,
          'conditionsConfig.metricGroupList',
          [],
        ).map(
          (datasetId: string) =>
            datasets?.find((datasetItem) => datasetItem.id === datasetId)?.label ?? datasetId,
        ),
      };

      // Fill Baseline config item with available plan metadata that isn't part of the stored config.
      // Refactor out if more metadata is needed in Baseline grid, for example "dataset last updated time".
      const availablePlanMetadata = getAvailablePlanMetadata(
        get(item, 'planLogicConfig.sourceType'),
        get(item, 'planLogicConfig.batchId'),
      );
      if (availablePlanMetadata?.tags) {
        baselineConfigItemData[BaselineColumnIds.TAGS] = availablePlanMetadata.tags;
      }

      return baselineConfigItemData;
    });

    const initialDataWithId = initialData.map((data, i) => ({ ...data, id: `${i}` }));

    // Only increment to ensure uniqueness through session
    initialDataWithId.forEach((_: any) => indexedId.current++);

    setDataSource(initialDataWithId);

    initialData.forEach((batch: any) => {
      fetchBatchDataAndAdd(batch.batchId);
    });

    setInlineEdits({});
  };

  useDelayedEffect(() => {
    if (!loaded && batchMetadata && havePlansBeenLoaded) {
      Object.entries(sourceTypeToListedPlans).forEach(([sourceType, listedPlans]) => {
        console.info(
          `${listedPlans.length} plans loaded for ${sourceType} options.\nNote: It's possible for different source types to have same plan options from API, which are then filtered in the column configs.`,
        );
      });

      setInitialData(batchMetadata);
      setLoaded(true);
    }
  }, [batchMetadata, loaded, sourceTypeToListedPlans]);

  const refreshData = async () => {
    const newBatchMetadata = await fetchBatchMetadata({
      batchId: batchMetadata?.batchId ?? '',
      requesterIdentity: alias,
    });

    setInitialData(newBatchMetadata);
  };

  const { columnConfigs, visibleColumns } = useMemo(() => {
    switch (batchMetadata?.costType) {
      case PlanTypeId.CAPEX:
        return generateColumnConfigCapex({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });
      case PlanTypeId.DEPRECIATION:
        return generateColumnConfigDepreciation({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });
      case PlanTypeId.REAL_ESTATE:
        return generateColumnConfigRealEstate({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });
      case PlanTypeId.THREE_P_TRANSPORTATION:
        return generateColumnConfigThreeP({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });
      case PlanTypeId.FIXED_COST_CONSOLIDATION:
        return generateColumnConfigFixedCostConsolidation({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });
      case PlanTypeId.UTR_COST_HC:
        return generateColumnConfigUTRCost({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
        });
      case PlanTypeId.OTR_WAREHOUSE_TRANSFER:
        return generateColumnConfigOTRWarehouseTransfer({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });
      case PlanTypeId.OTR_DOMESTIC_INBOUND:
        return generateColumnConfigOTRDomesticInbound({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });
      case PlanTypeId.OTR_FREIGHT_CAPITALIZATION:
        return generateColumnConfigOTRFreightCapitalization({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });
      case PlanTypeId.OTR_SUPPLY_TYPE:
        return generateColumnConfigOTRSupplyType({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });
      case PlanTypeId.SECURITY:
        return generateColumnConfigSecurityType({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });

      case PlanTypeId.TOPS_DOWN_FORECAST:
        return generateColumnConfigTopsDownForecastType({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });

      case PlanTypeId.CF_CONSOLIDATION:
        return generateColumnConfigCFConsolidationType({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });

      case PlanTypeId.JANITORIAL:
        return generateColumnConfigJanitorialType({
          batchMetadata,
          gridRef,
          sourceTypeToListedPlans,
          fetchBatchDataAndAdd,
          idToBatchMetadataMap,
          alias,
          t,
        });

      default:
        return { columnConfigs: {}, visibleColumns: [] };
    }
  }, [
    alias,
    batchMetadata,
    fetchBatchDataAndAdd,
    idToBatchMetadataMap,
    sourceTypeToListedPlans,
    t,
  ]);

  const gridConfig = {
    DataGrid: {
      stateStoring: {
        enabled: false,
      },
      editingDisabledColumns: [LastModifiedEnum.MODIFIED_AT, LastModifiedEnum.MODIFIED_BY],
    },
  };

  const addRowTransformer = (newRow: any) => {
    newRow[BaselineColumnIds.EXECUTING_ORDER] = {
      value: getNextExecutionOrder(gridRef),
      original: null,
      lastValidation: true,
    };

    if (
      batchMetadata?.costType === PlanTypeId.DEPRECIATION ||
      batchMetadata?.costType === PlanTypeId.REAL_ESTATE
    ) {
      newRow[BaselineColumnIds.UPDATE_DATASET_ON_SAVE] = {
        value: 'Yes',
        original: null,
        lastValidation: true,
      };
      newRow[BaselineColumnIds.METRIC_GROUP_LIST] = {
        value: [],
        original: null,
        lastValidation: false,
      };
    }

    const defaultToEmptyArray = [
      BaselineColumnIds.COUNTRY_LIST,
      BaselineColumnIds.SUB_GROUP_TYPE_LIST,
      BaselineColumnIds.BUILDING_STATUS_LIST,
      BaselineColumnIds.LOCATION_LIST,
      BaselineColumnIds.METRIC_LIST,
      BaselineColumnIds.ACCOUNT_LIST,
    ];

    defaultToEmptyArray.forEach((colId) => {
      if (visibleColumns?.includes(colId)) {
        newRow[colId] = {
          value: [],
          original: null,
          lastValidation: true,
        };
      }
    });
  };

  const getGridData = () => {
    const rows = [] as any;
    gridRef?.current?.api?.forEachNode((node: any) => rows.push(node));
    return rows;
  };

  // TODO: Refactor and optimize this function
  // (e.g. generalize consistent empty value handling, add types, clearer naming)
  const formatRowForSave = (node: any) => {
    if (!batchMetadata) {
      return [];
    }
    const row = cloneDeep(node.data);
    let changedFlag = false;

    for (const key in row) {
      const value = row[key];

      if (getCellValue(value) !== value) {
        if (value.value !== value.original) {
          changedFlag = true;
        }

        row[key] = getCellValue(value);
      }
    }

    const batchId = row[BaselineColumnIds.BATCH_ID];

    const datasets = getInputDatasets(batchMetadata, t);

    const formattedRow = {
      [BaselineConfigObjects.PLAN_LOGIC_CONFIG]: {
        [BaselineColumnIds.BATCH_ID]: batchId,
        [BaselineColumnIds.BATCH_NAME]: row[BaselineColumnIds.BATCH_NAME],
        [BaselineColumnIds.BATCH_VERSION]: '0',
        [BaselineColumnIds.SOURCE_LOGIC]: row[BaselineColumnIds.SOURCE_LOGIC] ?? null,
        [BaselineColumnIds.SOURCE_TYPE]: row[BaselineColumnIds.SOURCE_TYPE],
        [BaselineColumnIds.PERCENTAGE_ADJUSTMENT]: row[BaselineColumnIds.PERCENTAGE_ADJUSTMENT]
          ? parseFloat(row[BaselineColumnIds.PERCENTAGE_ADJUSTMENT].toString())
          : 0,
        [BaselineColumnIds.DATE]: row[BaselineColumnIds.DATE] ?? null,
      },
      [BaselineConfigObjects.CONDITIONS_CONFIG]: {
        [BaselineColumnIds.ACCOUNT_LIST]: row[BaselineColumnIds.ACCOUNT_LIST] ?? [],
        [BaselineColumnIds.COUNTRY_LIST]: row[BaselineColumnIds.COUNTRY_LIST] ?? [],
        [BaselineColumnIds.SUB_GROUP_TYPE_LIST]: row[BaselineColumnIds.SUB_GROUP_TYPE_LIST] ?? [],
        [BaselineColumnIds.LOCATION_LIST]: row[BaselineColumnIds.LOCATION_LIST] ?? [],
        [BaselineColumnIds.METRIC_GROUP_LIST]: (row[BaselineColumnIds.METRIC_GROUP_LIST] ?? []).map(
          (datasetName: string) =>
            datasets?.find((datasets) => datasets.label === datasetName)?.id ?? datasetName,
        ),
        [BaselineColumnIds.METRIC_LIST]: row[BaselineColumnIds.METRIC_LIST] ?? [],
        [BaselineColumnIds.SOURCE_PERIOD_LIST]: row[BaselineColumnIds.SOURCE_PERIOD_LIST] ?? [],
        [BaselineColumnIds.DESTINATION_PERIOD_LIST]:
          row[BaselineColumnIds.DESTINATION_PERIOD_LIST] ?? [],
      },
      [BaselineColumnIds.UPDATE_DATASET_ON_SAVE]:
        row[BaselineColumnIds.UPDATE_DATASET_ON_SAVE] === 'No' ? false : true,
      [BaselineColumnIds.MODIFIED_BY]: changedFlag ? '' : row[BaselineColumnIds.MODIFIED_BY],
      [BaselineColumnIds.MODIFIED_AT]: changedFlag ? '' : row[BaselineColumnIds.MODIFIED_AT],
      [BaselineColumnIds.EXECUTING_ORDER]: parseInt(
        row[BaselineColumnIds.EXECUTING_ORDER].toString(),
      ),
    };

    const testObject = {
      ...formattedRow[BaselineConfigObjects.PLAN_LOGIC_CONFIG],
      ...formattedRow[BaselineConfigObjects.CONDITIONS_CONFIG],
      ...formattedRow,
    } as any;

    let validationFailed = false;

    for (const key in columnConfigs) {
      const value = columnConfigs[key];
      if (value.required) {
        if (isEmpty(testObject[key]) && !isNumber(testObject[key])) {
          validationFailed = true;
          node.setDataValue(key, testObject[key]);
        }
      }
    }

    if (validationFailed) {
      return null;
    }

    return formattedRow;
  };

  const formatDataForSave = async () => {
    const gridData = getGridData();

    if (gridData === null) {
      return null;
    }

    const data = await Promise.all(gridData.map((node: any) => formatRowForSave(node)));
    if (data.some((row) => !row)) {
      addNotification({ type: 'error', content: 'Data Validation Failed' });
      return null;
    }
    const formattedData = data.sort((a, b) => (a.executingOrder > b.executingOrder ? 1 : -1));
    return formattedData;
  };

  const { triggerLoadBaseline } = useTriggerPlanLoadBaseline();

  const { mutate: updateBatchMetadata } = useBatchMetadataMutation({
    meta: {
      inProgressNotificationText: t('api_in_progress_update_baseline_metadata'),
      errorNotificationText: t('api_error_update_baseline_metadata'),
      successNotificationText: t('api_success_update_baseline_metadata'),
    },
    onSuccess: (data) => {
      if (data.batchId && data.costType !== PlanTypeId.FIXED_COST_CONSOLIDATION) {
        triggerLoadBaseline({ batchId: data.batchId });
      }
    },
    onSettled: () => {},
  });

  const saveCurrentTab = async () => {
    const formattedData = await formatDataForSave();
    if (!formattedData) {
      return;
    }

    updateBatchMetadata(
      {
        ...batchMetadata,
        baselineConfig: {
          ...batchMetadata?.baselineConfig,
          lastConfiguredTime: '',
          baselineConfigItemList: formattedData,
        },
      },
      {
        onSettled: () => {
          refreshData();
        },
      },
    );
  };

  const cloneRows = () => {
    const gridApi = gridRef?.current?.api;
    if (!gridApi) {
      return;
    }

    const selectedRows = gridApi.getSelectedRows();
    const rowsToAdd = selectedRows.map((row: any) => {
      const newRow = { id: `${indexedId.current++}` } as any;
      Object.keys(row).forEach((key) => {
        if (typeof row[key] === 'object' && !isArray(row[key])) {
          newRow[key] = {
            ...row[key],
            original: null,
          };
        } else if (key !== 'id') {
          newRow[key] = cellValueFormatter(row[key], null, true);
        }
      });

      const resultRow = {
        ...newRow,
        [BaselineColumnIds.EXECUTING_ORDER]: agCellValueFormatter(getNextExecutionOrder(gridRef)),
      };
      const headers = visibleColumns as any;
      agEditsCloning(resultRow, headers, setInlineEdits);
      return resultRow;
    });

    const tx = { addIndex: 0, add: rowsToAdd };
    gridApi.applyTransaction(tx);
    gridApi.deselectAll();
  };

  useDelayedEffect(() => {
    if (!gridRef?.current?.api) {
      return;
    }

    const orderList: number[] = [];
    const nodeList = [] as any;

    gridRef.current.api.forEachNode((node: any) => {
      orderList.push(parseInt(getCellValue(node.data!.executingOrder)));
      nodeList.push(node);
    });

    if (orderList.length && max(orderList)! <= orderList.length) {
      for (let i = 0; i < nodeList.length; i++) {
        if (orderList[i] < 1) {
          nodeList[i].setDataValue(BaselineColumnIds.EXECUTING_ORDER, 1000);
        }
      }
      return;
    }

    let currentCount = 0;
    while (orderList.length !== 0) {
      currentCount += 1;
      const currentMin = min(orderList) as number;
      const minIdx = orderList.indexOf(currentMin);
      const minNode = nodeList[minIdx];
      orderList.splice(minIdx, 1);
      nodeList.splice(minIdx, 1);

      if (currentMin !== currentCount) {
        minNode.setDataValue(BaselineColumnIds.EXECUTING_ORDER, currentCount);
      }
    }
  }, [inlineEdits]);

  const unsavedChangesMessage = !isEmpty(inlineEdits) && (
    <span style={{ color: 'red', fontSize: '12px', height: '15px', padding: 0 }}>
      <br />
      {t('unsaved_changes_message')}
    </span>
  );

  const { UnsavedChangesModal } = useUnsavedChangesModal({
    isHasUnsavedEdits: !isEmpty(inlineEdits),
    onDiscard: () => setInlineEdits({}),
    onSave: saveCurrentTab,
  });

  return (
    <>
      <AgEditableDataGrid
        planMetaData={batchMetadata}
        isDataLoading={!loaded}
        selectedGrid="data"
        domLayout="autoHeight"
        visibleColumns={visibleColumns}
        gridState={gridState}
        gridConfig={gridConfig}
        gridRef={gridRef}
        dataSource={dataSource}
        setDataSource={setDataSource}
        columnConfigs={columnConfigs}
        shouldSuppressPivotMode={true}
        inlineEdits={inlineEdits}
        setInlineEdits={setInlineEdits}
        indexedId={indexedId}
        autoSizeColumns={true}
        addRowTransformer={addRowTransformer}
        inlineEditHeaders={visibleColumns}
        onClickSave={saveCurrentTab}
        onClickRefresh={refreshData}
        onClickClone={cloneRows}
        colorUneditable={true}
        disableSelectAllMessage={true}
        containerTitle="Import Baseline Data"
        showSaveWithoutEdits={true}
        containerDescription={
          <span>
            {t('baseline_description')}
            {unsavedChangesMessage}
          </span>
        }
      />

      {UnsavedChangesModal}
    </>
  );
};

export default CostPlanningBaselineGeneral;
