import { useMemo } from 'react';
import { IAggFunc } from 'ag-grid-community';

export const useCsrmGridParams = () => {
  const aggFuncs = useMemo<Record<string, IAggFunc>>(
    () => ({
      sum: (params) => params.values.reduce((prev, curr) => prev + parseFloat(curr), 0),
      min: (params) => Math.min(...params.values.map((o) => parseFloat(o))),
      max: (params) => Math.max(...params.values.map((o) => parseFloat(o))),
      avg: (params) => {
        const { values } = params;
        if (values.length === 0) return 0;
        return values.reduce((prev, curr) => prev + parseFloat(curr), 0) / values.length;
      },
    }),
    [],
  );

  return { aggFuncs };
};
