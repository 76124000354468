/* eslint-disable no-case-declarations */
import { BatchesList } from '@amzn/fox-den-cost-planning-lambda';
import {
  CellValueChangedEvent,
  EditableCallbackParams,
  IRowNode,
  ValueFormatterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { TFunction } from 'i18next';
import { isEmpty, get as lodashGet, reduce, uniqBy } from 'lodash';
import { RefObject } from 'react';
import {
  ICustomizedAgGridColumnConfig,
  getCellValue,
} from 'src/common/ag-grid/editable-datagrid/AgGridConfigHelper';
import {
  ICellData,
  IValidationResult,
} from 'src/common/ag-grid/editable-datagrid/AgGridValidators';
import { getBaselineSourceTypes } from 'src/pages/plan-manager-page/plan-baseline-tab/BaselineGrid';
import {
  BaselineColumnIds,
  BaselineColumnNames,
  BaselineDatasourceNames,
} from 'src/pages/plan-manager-page/plan-baseline-tab/BaselineModel';
import {
  generateColumnConfigBase,
  getCellValueForCol,
  resetRowNodeValues,
} from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/columnConfigBase';
import { getInputDatasets } from 'src/pages/plan-manager-page/plan-input-tab/planInputConfig';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';
import { PlanTypeId } from 'src/utils/planning/planetModel';
import { currentModifiedAt } from 'src/utils/time';

interface ICellEditorParams {
  node: IRowNode;
}

export interface IBaselinePlanOption {
  id: string;
  name: string;
}

type IRowData = Record<BaselineColumnIds, ICellData | string | string[]>;

const BASELINE_COLUMNS: string[] = [
  BaselineColumnIds.EXECUTING_ORDER,
  BaselineColumnIds.SOURCE_TYPE,
  BaselineColumnIds.BATCH_NAME,
  BaselineColumnIds.BATCH_ID,
  BaselineColumnIds.TAGS,
  BaselineColumnIds.METRIC_GROUP_LIST,
  BaselineColumnIds.COUNTRY_LIST,
  BaselineColumnIds.UPDATE_DATASET_ON_SAVE,
  BaselineColumnIds.MODIFIED_BY,
  BaselineColumnIds.MODIFIED_AT,
];

export const generateColumnConfigOTRWarehouseTransfer = ({
  batchMetadata,
  gridRef,
  sourceTypeToListedPlans,
  fetchBatchDataAndAdd,
  idToBatchMetadataMap,
  alias,
  t,
}: {
  batchMetadata: IBatchMetadataParsed | undefined;
  gridRef: RefObject<AgGridReact>;
  sourceTypeToListedPlans: Record<string, BatchesList> | undefined;
  fetchBatchDataAndAdd: (batchId: string) => void;
  idToBatchMetadataMap: Record<string, IBatchMetadataParsed>;
  alias: string;
  t: TFunction<'translation', undefined>;
}) => {
  if (!batchMetadata) return { columnConfigs: {}, visibleColumns: BASELINE_COLUMNS };

  const metricGroupListValidationFn =
    (params: ValueSetterParams) =>
    (newValue: unknown): IValidationResult => {
      if (
        [BaselineDatasourceNames.DEPRECIATION_PLAN, BaselineDatasourceNames.PLAN].includes(
          getCellValueForCol(params, BaselineColumnIds.SOURCE_TYPE),
        ) &&
        isEmpty(newValue)
      ) {
        return {
          result: false,
          message: 'Metric Groups should not be empty.',
        };
      }

      return {
        result: true,
        message: 'Validation Passed',
      };
    };

  const getColumnDataSource = (
    rowNode: IRowNode,
    columnId: BaselineColumnIds,
  ): IBaselinePlanOption[] => {
    const rowData: IRowData = rowNode.data || {};
    const selectedBatchId: string = getCellValue(rowData[BaselineColumnIds.BATCH_ID]);
    const selectedSourceType: string = getCellValue(rowData[BaselineColumnIds.SOURCE_TYPE]);
    const planSelectedInRow: any = idToBatchMetadataMap[selectedBatchId];

    switch (columnId) {
      case BaselineColumnIds.BATCH_NAME:
        // Plans should be unique by batchName.
        if (!sourceTypeToListedPlans?.[selectedSourceType]) {
          return [];
        }

        return sourceTypeToListedPlans[selectedSourceType]
          .sort((a, b) => (b.lastUpdatedOn ?? '').localeCompare(a?.lastUpdatedOn ?? ''))
          .filter((plan) => {
            if (selectedSourceType === BaselineDatasourceNames.OTR_WAREHOUSE_TRANSFER_PLAN) {
              return plan.costType === PlanTypeId.OTR_WAREHOUSE_TRANSFER;
            }
            return false;
          })
          .map(({ batchName }: any) => ({
            id: batchName,
            name: batchName,
          }));

      case BaselineColumnIds.COUNTRY_LIST:
        if (!planSelectedInRow) {
          return [];
        }
        const countries: string[] = planSelectedInRow.countries;
        const countriesDataSource = countries?.map((country) => ({
          name: country,
          id: country,
        }));

        return uniqBy(countriesDataSource, 'id');
      case BaselineColumnIds.METRIC_GROUP_LIST:
        const datasets = getInputDatasets(batchMetadata, t);
        const { datasetLocations } = batchMetadata;
        const datasetsSource = datasetLocations
          ?.filter(
            (dataset) =>
              // Don't display output datasets
              !dataset.datasetName.includes('output') &&
              // Don't display if should hide from baseline
              !dataset.shouldHideFromBaseline &&
              // Grab only datasets that exist in the FE
              datasets?.find((datasetItem) => datasetItem.id === dataset.datasetName),
          )
          .map((dataset: any) => ({
            name:
              datasets?.find((datasetItem) => datasetItem.id === dataset.datasetName)?.label ??
              dataset.datasetName,
            id:
              datasets?.find((datasetItem) => datasetItem.id === dataset.datasetName)?.label ??
              dataset.datasetName,
          }));

        return uniqBy(datasetsSource, 'id');
      default:
        return [];
    }
  };

  // Baseline has a number of shared "basic" columns, however, each module may have differing requirements.
  // We thus exclude shared columns that were not defined in BASELINE_COLUMNS.
  const sharedColumnConfigs = reduce(
    generateColumnConfigBase({
      gridRef,
      alias,
    }),
    (accConfigs: any, value, key) => {
      if (BASELINE_COLUMNS.includes(key)) {
        accConfigs[key] = value;
      }
      return accConfigs;
    },
    {},
  );

  const columnConfigs: Record<string, ICustomizedAgGridColumnConfig> = {
    ...sharedColumnConfigs,
    [BaselineColumnIds.SOURCE_TYPE]: {
      required: true,
      headerName: BaselineColumnNames.SOURCE_TYPE,
      editor: 'AgTreeSelect',
      editorParams: {
        treeDataSource: getBaselineSourceTypes(batchMetadata?.costType || ''),
      },
      onCellValueChanged: (params: CellValueChangedEvent) => {
        resetRowNodeValues(
          params.node,
          [BaselineColumnIds.BATCH_NAME, BaselineColumnIds.BATCH_ID, BaselineColumnIds.TAGS],
          [BaselineColumnIds.COUNTRY_LIST, BaselineColumnIds.METRIC_GROUP_LIST],
        );
      },
    },
    [BaselineColumnIds.BATCH_NAME]: {
      headerName: BaselineColumnNames.BATCH_NAME,
      required: true,
      editor: 'AgTreeSelect',
      editorParams: (params: ICellEditorParams) => {
        const editorParams = {
          treeDataSource: getColumnDataSource(params?.node, BaselineColumnIds.BATCH_NAME),
          largeDropdown: true,
        };
        return editorParams;
      },
      editable: (params: EditableCallbackParams): boolean =>
        getColumnDataSource(params?.node, BaselineColumnIds.BATCH_NAME).length !== 0,
      onCellValueChanged: (params: CellValueChangedEvent) => {
        const selectedPlanName: string = getCellValueForCol(params);
        const selectedSourceType: string = getCellValueForCol(
          params,
          BaselineColumnIds.SOURCE_TYPE,
        );

        if (!selectedPlanName) return;

        const selectedPlan: any = sourceTypeToListedPlans?.[selectedSourceType]?.find(
          (plan: any) => plan.batchName === selectedPlanName,
        );
        const selectedBatchId = selectedPlan?.batchId;

        params.node.setDataValue(BaselineColumnIds.BATCH_ID, selectedBatchId);

        fetchBatchDataAndAdd(selectedBatchId);

        resetRowNodeValues(
          params.node,
          [],
          [BaselineColumnIds.COUNTRY_LIST, BaselineColumnIds.METRIC_GROUP_LIST],
        );

        params.node.setDataValue(BaselineColumnIds.TAGS, lodashGet(selectedPlan, 'tags', ''));

        params.node.setDataValue(BaselineColumnIds.MODIFIED_BY, alias);
        params.node.setDataValue(BaselineColumnIds.MODIFIED_AT, currentModifiedAt());
      },
    },
    [BaselineColumnIds.TAGS]: {
      headerName: BaselineColumnNames.TAGS,
      editable: false,
    },
    [BaselineColumnIds.METRIC_GROUP_LIST]: {
      headerName: BaselineColumnNames.METRIC_GROUP_LIST,
      editor: 'AgTreeMultiSelect',
      editorParams: (params: ICellEditorParams) => {
        const editorParams = {
          treeDataSource: getColumnDataSource(params?.node, BaselineColumnIds.METRIC_GROUP_LIST),
          selectAllUnselectsAll: false,
          dropDownOptions: { minWidth: '500px' },
        };
        return editorParams;
      },
      editable: (params: EditableCallbackParams): boolean =>
        getColumnDataSource(params?.node, BaselineColumnIds.METRIC_GROUP_LIST).length !== 0 &&
        [BaselineDatasourceNames.OTR_WAREHOUSE_TRANSFER_PLAN].includes(
          getCellValueForCol(params, BaselineColumnIds.SOURCE_TYPE),
        ),
      onCellValueChanged: (params: CellValueChangedEvent) => {
        params.node.setDataValue(BaselineColumnIds.MODIFIED_BY, alias);
        params.node.setDataValue(BaselineColumnIds.MODIFIED_AT, currentModifiedAt());
      },
      validationFns: [metricGroupListValidationFn],
    },
    [BaselineColumnIds.COUNTRY_LIST]: {
      headerName: BaselineColumnNames.COUNTRY_LIST,
      editor: 'AgTreeMultiSelect',
      valueFormatter: (params: ValueFormatterParams) => {
        const value = getCellValueForCol(params);
        if (!value || value.length === 0) {
          return 'All';
        }
        return value;
      },
      editorParams: (params: ICellEditorParams) => {
        const editorParams = {
          treeDataSource: getColumnDataSource(params?.node, BaselineColumnIds.COUNTRY_LIST),
          selectAllUnselectsAll: false,
        };
        return editorParams;
      },
      editable: (params: EditableCallbackParams): boolean =>
        getColumnDataSource(params?.node, BaselineColumnIds.COUNTRY_LIST).length !== 0 &&
        [BaselineDatasourceNames.OTR_WAREHOUSE_TRANSFER_PLAN].includes(
          getCellValueForCol(params, BaselineColumnIds.SOURCE_TYPE),
        ),
      onCellValueChanged: (params: CellValueChangedEvent) => {
        params.node.setDataValue(BaselineColumnIds.MODIFIED_BY, alias);
        params.node.setDataValue(BaselineColumnIds.MODIFIED_AT, currentModifiedAt());
      },
    },
    [BaselineColumnIds.UPDATE_DATASET_ON_SAVE]: {
      headerName: BaselineColumnNames.UPDATE_DATASET_ON_SAVE,
      editor: 'AgTreeSelect',
      editable: (params: EditableCallbackParams): boolean =>
        [BaselineDatasourceNames.OTR_WAREHOUSE_TRANSFER_PLAN].includes(
          getCellValueForCol(params, BaselineColumnIds.SOURCE_TYPE),
        ),
      editorParams: (_: ICellEditorParams) => {
        const editorParams = {
          treeDataSource: [
            { name: 'Yes', id: 'Yes' },
            { name: 'No', id: 'No' },
          ],
        };
        return editorParams;
      },
      onCellValueChanged: (params: CellValueChangedEvent) => {
        params.node.setDataValue(BaselineColumnIds.MODIFIED_BY, alias);
        params.node.setDataValue(BaselineColumnIds.MODIFIED_AT, currentModifiedAt());
      },
    },
  };

  return { columnConfigs, visibleColumns: BASELINE_COLUMNS };
};
