import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { QUERY_KEYS, useQueryWithNotifications } from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';
import { OTR_PLAN_TYPES, PlanTypeId, RegionId } from 'src/utils/planning/planetModel';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';

const parseJsonStringToArray = <T = any>(s?: string): T[] => (s ? JSON.parse(s) : []);

interface IUseBatchDimensionValuesSettings {
  disabled?: boolean;
}

interface IUseBatchBusinessRulesInput {
  datasetName: string;
}

export interface ParsedBusinessRuleCondition {
  relation?: string;
  column?: string;
  function?: string;
  skipifnull?: boolean;
  value: string;
  parameters?: string;
}

interface ParsedBusinessRuleFilter {
  metadata: string;
  relation: string;
  values: string[];
}

export interface ParsedBusinessRule {
  ruleType?: string;
  ruleFilters?: ParsedBusinessRuleFilter[];
  conditions: ParsedBusinessRuleCondition[];
  messageType?: string;
  message: string;
}

export const useBatchBusinessRules = (
  input: IUseBatchBusinessRulesInput,
  settings: IUseBatchDimensionValuesSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const { batchId } = useParams();
  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const getDimensionName = (input: IUseBatchBusinessRulesInput): string => {
    const planType = batchMetadata?.costType as PlanTypeId;
    const region = batchMetadata?.region as RegionId;

    if (OTR_PLAN_TYPES.has(planType) || planType === PlanTypeId.FIXED_COST_CONSOLIDATION) {
      return `business_rules#${region}#${input.datasetName}`;
    }

    return `business_rules#${input.datasetName}`;
  };

  const query = useQueryWithNotifications({
    queryKey: [
      QUERY_KEYS.DIMENSION_BUSINESS_RULES,
      batchMetadata?.batchId,
      batchMetadata?.costType,
      input,
      alias,
    ],
    queryFn: async () => {
      const dimensionName = getDimensionName(input);

      const result = await FoxPlanningApi.getDimensionValues({
        batchId: batchMetadata?.batchId,
        dimensionLevel: batchMetadata?.costType,
        dimensionList: [dimensionName],
        replaceWithReplacement: true,
        batchVersion: '0',
        requesterIdentity: alias,
      });

      const parsedBusinessRules = parseJsonStringToArray<ParsedBusinessRule>(
        result?.dimensions?.[dimensionName],
      );

      /** filter out unqualified rules */
      const filteredBusinessRules = parsedBusinessRules.filter((o) => {
        for (const r of o.ruleFilters ?? []) {
          if (r.relation === '=' && r.metadata !== r.values[0]) return false;
        }
        return true;
      });

      return filteredBusinessRules;
    },
    meta: { errorNotificationText: t('api_error_fetch_business_rules') },
    enabled: !settings.disabled,
  });
  return query;
};

interface IUseBatchUpsertDatasetsInput {
  planType: PlanTypeId;
}

export const useBatchUpsertDatasets = (
  input: IUseBatchUpsertDatasetsInput,
  settings: IUseBatchDimensionValuesSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.DIMENSION_UPSERT_DATASETS, input, alias],
    queryFn: async () => {
      const dimensionName = 'UpsertDatasets';
      const result = await FoxPlanningApi.getDimensionValues({
        dimensionLevel: input.planType,
        dimensionList: [dimensionName],
        requesterIdentity: alias,
      });
      return parseJsonStringToArray<string>(result?.dimensions?.[dimensionName]);
    },
    meta: { errorNotificationText: t('api_error_fetch_upsert_datasets') },
    enabled: !settings.disabled,
  });
  return query;
};

interface IUseStaticFilterInput {
  planType: string;
  region: string;
  subGroup: string;
}

export const useStaticFilterList = (
  input: IUseStaticFilterInput,
  settings: IUseBatchDimensionValuesSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.DIMENSION_STATIC_FILTER_LIST, input, alias],
    queryFn: async () => {
      const dimensionName = `staticSiteList#${input.region}#${input.subGroup}`;
      const result = await FoxPlanningApi.getDimensionValues({
        dimensionLevel: input.planType,
        dimensionList: [dimensionName],
        requesterIdentity: alias,
      });
      return parseJsonStringToArray<string>(result?.dimensions?.[dimensionName]);
    },
    meta: { errorNotificationText: t('api_error_fetch_static_filter_list') },
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: !settings.disabled,
  });
  return query;
};

interface IUsePortfolioIdsInput {
  planType: string;
}

export const usePortfolioIds = (
  input: IUsePortfolioIdsInput,
  settings: IUseBatchDimensionValuesSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.DIMENSION_PORTFOLIO_IDS, input, alias],
    queryFn: async () => {
      const dimensionName = 'portfolioIds';
      const result = await FoxPlanningApi.getDimensionValues({
        dimensionLevel: input.planType,
        dimensionList: [dimensionName],
        requesterIdentity: alias,
      });
      return parseJsonStringToArray<string>(result?.dimensions?.[dimensionName]);
    },
    meta: { errorNotificationText: t('api_error_fetch_portfolio_ids') },
    enabled: !settings.disabled,
  });

  return query;
};

interface IUseScenariosInput {
  planType: string;
}

export const useScenarios = (
  input: IUseScenariosInput,
  settings: IUseBatchDimensionValuesSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.DIMENSION_SCENARIOS, input, alias],
    queryFn: async () => {
      const dimensionName = 'scenarios';
      const result = await FoxPlanningApi.getDimensionValues({
        dimensionLevel: input.planType,
        dimensionList: [dimensionName],
        requesterIdentity: alias,
      });
      return parseJsonStringToArray<string>(result?.dimensions?.[dimensionName]);
    },
    meta: { errorNotificationText: t('api_error_fetch_scenarios') },
    enabled: !settings.disabled,
  });

  return query;
};
