import { GetDatasetDataRequest } from '@amzn/fox-den-cost-planning-lambda';
import { useTranslation } from 'react-i18next';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { QUERY_KEYS, useQueryWithNotifications } from 'src/api/api-utils';

export const useDatasetExportLink = (request: GetDatasetDataRequest | undefined) => {
  const { t } = useTranslation();

  return useQueryWithNotifications({
    queryKey: [QUERY_KEYS.PLAN_SSRM_DATASET_EXPORT, request],
    queryFn: async () => {
      if (!request) return undefined;

      const result = await FoxPlanningApi.getDatasetData({
        ...request,
        startRow: null,
        endRow: null,
        isExport: true,
      });

      return result.locations?.[0];
    },
    meta: { errorNotificationText: t('api_error_fetch_dataset_data') },
    cacheTime: 0,
    staleTime: Infinity,
    enabled: false,
  });
};
