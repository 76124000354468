import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  CellSelectionOptions,
  ColumnPivotModeChangedEvent,
  ModelUpdatedEvent,
  ToolPanelVisibleChangedEvent,
} from 'ag-grid-community';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';
import { usePlanPageContext } from 'src/pages/plan-manager-page/PlanManagerPage';
import { useAgGridResizeColumns } from 'src/common/ag-grid/useAgGridResizeColumns';
import { useAgGridCalculatedHeight } from 'src/common/ag-grid/useAgGridCalculatedHeight';

interface UseSsrmGridParamsProps {
  currentDataset: SupportedDatasetType;
  setCurrentGridPivotMode: (isPivotMode: boolean) => void;
  setCurrentGridToolPanel: (toolPanel: string | undefined) => void;
}

export const useSsrmGridParams = ({
  currentDataset,
  setCurrentGridPivotMode,
  setCurrentGridToolPanel,
}: UseSsrmGridParamsProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { ssrmStaleDatasets, setSsrmStaleDatasets } = usePlanPageContext();

  const { gridHeight } = useAgGridCalculatedHeight(containerRef, 65, false);
  const { resizeColumns } = useAgGridResizeColumns(gridRef, containerRef);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [onRefreshStaleDataset, setOnRefreshStaleDataset] = useState<(() => void) | null>(null);

  const cellSelection = useMemo<CellSelectionOptions>(() => ({ handle: { mode: 'range' } }), []);

  const onModelUpdated = useCallback(
    (e: ModelUpdatedEvent) => {
      const isFetchStarted = e.api.getGridOption('serverSideDatasource');
      const rowCount = e.api.getDisplayedRowCount();
      if (isFetchStarted && rowCount === 0) setTimeout(() => e.api.showNoRowsOverlay(), 100);
      resizeColumns();
    },
    [resizeColumns],
  );

  const onColumnPivotModeChanged = useCallback(
    (e: ColumnPivotModeChangedEvent) => setCurrentGridPivotMode(e.api.isPivotMode()),
    [setCurrentGridPivotMode],
  );

  const onToolPanelVisibleChanged = useCallback(
    (e: ToolPanelVisibleChangedEvent) => setCurrentGridToolPanel(e.visible ? e.key : undefined),
    [setCurrentGridToolPanel],
  );

  /** refresh data grid and refetch everything if current dataset is marked as stale */
  useEffect(() => {
    if (ssrmStaleDatasets.has(currentDataset)) {
      gridRef.current?.api?.refreshServerSide({ purge: true });
      setSsrmStaleDatasets(new Set([...ssrmStaleDatasets].filter((o) => o !== currentDataset)));
      onRefreshStaleDataset?.();
    }
  }, [currentDataset, onRefreshStaleDataset, setSsrmStaleDatasets, ssrmStaleDatasets]);

  return {
    gridRef,
    containerRef,
    gridHeight,
    cellSelection,
    isFullScreen,
    resizeColumns,
    onModelUpdated,
    onColumnPivotModeChanged,
    onToolPanelVisibleChanged,
    setIsFullScreen,
    setOnRefreshStaleDataset,
  };
};
