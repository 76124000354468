import { RefObject, useMemo } from 'react';
import { SideBarDef } from 'ag-grid-community';
import { FullScreen } from '@chiragrupani/fullscreen-react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { useDarkModeContext } from 'src/hooks/useDarkModeContext';
import 'ag-grid-enterprise';

interface AgGridBaseWrapperProps
  extends Omit<
    AgGridReactProps,
    'sideBar' | 'stopEditingWhenCellsLoseFocus' | 'processCellForClipboard'
  > {
  gridRef: RefObject<AgGridReact>;
  gridHeight?: string | number;
  gridToolPanel?: string;
  isFullScreen: boolean;
  setFullScreen: (isFull: boolean) => void;
}

export const AgGridBaseWrapper = (props: AgGridBaseWrapperProps) => {
  const { gridRef, gridHeight, gridToolPanel, isFullScreen, setFullScreen, ...agGridProps } = props;

  const { isDarkMode } = useDarkModeContext();

  const sideBar = useMemo<SideBarDef>(
    () => ({ toolPanels: ['columns', 'filters'], defaultToolPanel: gridToolPanel }),
    [gridToolPanel],
  );

  // 100% if full screen, gridHeight parameter otherwise with 60vh as a default
  const height = isFullScreen ? '100%' : gridHeight ?? '60vh';

  return (
    <FullScreen isFullScreen={isFullScreen} onChange={setFullScreen}>
      <div
        className={isDarkMode ? 'ag-theme-balham-dark' : 'ag-theme-balham'}
        style={{ width: '100%', height, fontFamily: 'Amazon Ember' }}
      >
        <AgGridReact
          ref={gridRef}
          sideBar={sideBar}
          stopEditingWhenCellsLoseFocus={true}
          processCellForClipboard={(params) => params.value}
          {...agGridProps}
        />
      </div>
    </FullScreen>
  );
};
