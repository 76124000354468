import { Box, Button, Modal, SpaceBetween, Table } from '@amzn/awsui-components-react/polaris';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotificationContext } from 'src/hooks/useNotificationContext';
import { downloadFile } from 'src/utils/errorMessage';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';

/**
 * This component render error modal
 */
const GlobalErrorModal: FC = () => {
  const { t } = useTranslation();
  const { globalErrorModal, clearGlobalErrorModal } = useNotificationContext();
  const { data: featureFlags } = useFeatureFlags();

  if (!globalErrorModal) return null;

  return (
    <Modal
      data-testid="error-modal"
      size="large"
      header={t('error_modal_title')}
      onDismiss={clearGlobalErrorModal}
      footer={
        globalErrorModal.downloadLink ? (
          <Box float="right">
            <Button
              data-testid="error-modal-download-button"
              onClick={() =>
                downloadFile(
                  globalErrorModal.downloadLink!,
                  featureFlags?.feS3UrlGeneration ?? false,
                )
              }
              variant="normal"
            >
              {t('download')}
            </Button>
          </Box>
        ) : null
      }
      visible={!!globalErrorModal}
    >
      <SpaceBetween direction="vertical" size="s">
        <span data-testid="error-modal-message">{globalErrorModal.message}</span>
        {globalErrorModal.table ? (
          <Table
            data-testid="error-modal-table"
            items={globalErrorModal.table.items}
            columnDefinitions={globalErrorModal.table.columnDefinitions}
            wrapLines
          />
        ) : null}
      </SpaceBetween>
    </Modal>
  );
};

export default GlobalErrorModal;
