import { cloneDeep } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import { SLOT_DESCRIPTION_MAX_LEN } from 'src/constants';
import {
  PlanCreateFields,
  PlanCreateFieldIds,
  getDefaultCountries,
  getDefaultCurrencyOption,
  getDefaultPlanCycleOption,
  getDefaultPeriodTypeOption,
  getDefaultPlanYearOption,
  getDefaultStartEndDate,
} from 'src/pages/create-plan-page/createPlanBusinessLogic';
import {
  BUSINESS_GROUPS,
  PeriodTypeId,
  REGIONS,
  SUB_GROUPS,
  useSupportedPlanTypes,
} from 'src/utils/planning/planetModel';
import {
  BusinessFilterType,
  useGetUpdatedSelectedGroupFilters,
  useSupportedBusinessGroups,
} from 'src/utils/planning/supportedBusinessGroups';

const useTransformFormFieldContent = () => {
  const { getUpdatedSelectedGroupFilters } = useGetUpdatedSelectedGroupFilters();

  const { data: featureFlags } = useFeatureFlags();

  const transformFormFieldContent = useCallback(
    (originalFormContent: PlanCreateFields, fieldId: PlanCreateFieldIds, newValue: any) => {
      let newFormContent = cloneDeep(originalFormContent);
      if (fieldId === PlanCreateFieldIds.PLAN_TYPE) {
        const updatedSelectedFilters = getUpdatedSelectedGroupFilters(
          originalFormContent,
          BusinessFilterType.PLAN_TYPE,
          newValue,
        );
        newFormContent = { ...newFormContent, ...updatedSelectedFilters };
      } else if (fieldId === PlanCreateFieldIds.BUSINESS_GROUP) {
        const updatedSelectedFilters = getUpdatedSelectedGroupFilters(
          originalFormContent,
          BusinessFilterType.BUSINESS_GROUP,
          newValue,
        );
        newFormContent = { ...newFormContent, ...updatedSelectedFilters };
      } else if (fieldId === PlanCreateFieldIds.SUB_GROUP) {
        const updatedSelectedFilters = getUpdatedSelectedGroupFilters(
          originalFormContent,
          BusinessFilterType.SUB_GROUP,
          newValue,
        );
        newFormContent = { ...newFormContent, ...updatedSelectedFilters };
      } else if (fieldId === PlanCreateFieldIds.REGION) {
        const updatedSelectedFilters = getUpdatedSelectedGroupFilters(
          originalFormContent,
          BusinessFilterType.REGION,
          newValue,
        );
        newFormContent = { ...newFormContent, ...updatedSelectedFilters };
      } else if (fieldId === PlanCreateFieldIds.COUNTRIES) {
        newFormContent.countries = newValue;
      } else if (fieldId === PlanCreateFieldIds.PLAN_CYCLE) {
        newFormContent.planCycle = newValue;
      } else if (fieldId === PlanCreateFieldIds.START_YEAR) {
        newFormContent.startYear = newValue;
      } else if (fieldId === PlanCreateFieldIds.PERIOD_TYPE) {
        newFormContent.periodType = newValue;
      } else if (fieldId === PlanCreateFieldIds.START_DATE) {
        newFormContent.startDate = newValue;
      } else if (fieldId === PlanCreateFieldIds.END_DATE) {
        newFormContent.endDate = newValue;
      } else if (fieldId === PlanCreateFieldIds.CURRENCY) {
        newFormContent.currency = newValue;
      } else if (fieldId === PlanCreateFieldIds.SLOT_DESCRIPTION) {
        newFormContent.slotDescription = newValue.slice(0, SLOT_DESCRIPTION_MAX_LEN);
      }

      const planTypeChanged =
        newFormContent.planType?.value !== originalFormContent.planType?.value;
      const planRegionChanged = newFormContent.region?.value !== originalFormContent.region?.value;

      if (planTypeChanged || planRegionChanged) {
        newFormContent.countries = getDefaultCountries(newFormContent);
      }

      if (planTypeChanged) {
        newFormContent.planCycle = getDefaultPlanCycleOption(newFormContent);
        newFormContent.startYear = getDefaultPlanYearOption();
        newFormContent.periodType = getDefaultPeriodTypeOption(newFormContent, featureFlags);
        newFormContent.currency = getDefaultCurrencyOption(newFormContent);
      }

      const planCycleChanged =
        newFormContent.planCycle?.value !== originalFormContent.planCycle?.value;
      const planPeriodTypeChanged =
        newFormContent.periodType?.value !== originalFormContent.periodType?.value;
      const planStartYearChanged =
        newFormContent.startYear?.value !== originalFormContent.startYear?.value;

      if (planCycleChanged || planPeriodTypeChanged || planStartYearChanged) {
        const { startDate, endDate } = getDefaultStartEndDate(newFormContent);
        newFormContent.startDate = startDate;
        newFormContent.endDate = endDate;
      }

      return newFormContent;
    },
    [featureFlags, getUpdatedSelectedGroupFilters],
  );

  return { transformFormFieldContent };
};

export const useCreatePlanFormManager = (state: Record<BusinessFilterType, string> | null) => {
  const { supportedPlanTypes } = useSupportedPlanTypes();
  const { supportedBusinessGroups } = useSupportedBusinessGroups();
  const { transformFormFieldContent } = useTransformFormFieldContent();

  const initialCreateFields = useMemo(() => {
    const statePlanType = state?.[BusinessFilterType.PLAN_TYPE];
    const stateBusinessGroup = state?.[BusinessFilterType.BUSINESS_GROUP];
    const stateSubGroup = state?.[BusinessFilterType.SUB_GROUP];
    const stateRegion = state?.[BusinessFilterType.REGION];

    const stateParamsValid = supportedBusinessGroups.some(
      (item) =>
        item.planType === statePlanType &&
        item.businessGroup === stateBusinessGroup &&
        item.subGroup === stateSubGroup &&
        item.region === stateRegion,
    );

    let initialFields = { ...new PlanCreateFields() };

    if (stateParamsValid) {
      initialFields = transformFormFieldContent(
        initialFields,
        PlanCreateFieldIds.PLAN_TYPE,
        supportedPlanTypes.find((item) => item.value === statePlanType) ?? null,
      );
      initialFields = transformFormFieldContent(
        initialFields,
        PlanCreateFieldIds.BUSINESS_GROUP,
        BUSINESS_GROUPS.find((item) => item.value === stateBusinessGroup) ?? null,
      );
      initialFields = transformFormFieldContent(
        initialFields,
        PlanCreateFieldIds.SUB_GROUP,
        SUB_GROUPS.find((item) => item.value === stateSubGroup) ?? null,
      );
      initialFields = transformFormFieldContent(
        initialFields,
        PlanCreateFieldIds.REGION,
        REGIONS.find((item) => item.value === stateRegion) ?? null,
      );
    }

    return initialFields;
  }, [state, supportedBusinessGroups, supportedPlanTypes, transformFormFieldContent]);

  const [formContent, setFormContent] = useState(initialCreateFields as PlanCreateFields);

  const onUpdateFormField = (fieldId: PlanCreateFieldIds, newValue: any) => {
    const newFormContent = transformFormFieldContent(formContent, fieldId, newValue);
    setFormContent(newFormContent);
  };

  const canCreatePlan = !!(
    formContent.businessGroup &&
    formContent.countries.length !== 0 &&
    (formContent.startDate.length === 10 ||
      ((formContent?.periodType?.value === PeriodTypeId.MONTHLY ||
        formContent?.periodType?.value === PeriodTypeId.QUARTERLY) &&
        formContent.startDate.length === 7)) &&
    (formContent.endDate.length === 10 ||
      ((formContent?.periodType?.value === PeriodTypeId.MONTHLY ||
        formContent?.periodType?.value === PeriodTypeId.QUARTERLY) &&
        formContent.endDate.length === 7)) &&
    formContent.periodType &&
    formContent.planCycle &&
    formContent.region &&
    formContent.subGroup &&
    formContent.planType &&
    formContent.currency &&
    formContent.startDate <= formContent.endDate
  );

  return {
    canCreatePlan,
    formContent,
    onUpdateFormField,
  };
};
