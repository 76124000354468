import { AscDescEnum, GetDatasetDataRequest } from '@amzn/fox-den-cost-planning-lambda';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { IServerSideGetRowsRequest } from 'ag-grid-community';
import { useParams } from 'react-router-dom';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { useUserAlias } from 'src/hooks/useUserAlias';
import { useNotificationContext } from 'src/hooks/useNotificationContext';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';

/**
 * mostly we use react-query hooks to manage api calls in this package
 * however, to work seamlessly with ag grid server side row model,
 * we need to use a general async function for fetching datasets
 */

const getFilterModel = (request: IServerSideGetRowsRequest) =>
  Object.entries(request.filterModel || {}).reduce(
    (prev, [key, { values }]) => ({ ...prev, [key]: values }),
    {},
  );

const getSortModel = (request: IServerSideGetRowsRequest) =>
  request.sortModel.map((o) => ({
    column: o.colId,
    ascDesc: o.sort.toUpperCase() as AscDescEnum,
  }));

export const useSsrmDataset = (datasetName: SupportedDatasetType) => {
  const { t } = useTranslation();
  const { alias } = useUserAlias();
  const { batchId } = useParams();
  const { addNotificationForApiError } = useNotificationContext();

  const [fetchingCount, setFetchingCount] = useState(0);

  const getDataset = useCallback(
    async (request: IServerSideGetRowsRequest) => {
      const datasetRequest: GetDatasetDataRequest = {
        batchId: batchId || '',
        datasetName,
        startRow: request.startRow,
        endRow: request.endRow,
        filterModel: getFilterModel(request),
        sortModel: getSortModel(request),
        isExport: false,
        batchVersion: '0',
        requesterIdentity: alias,
      };

      const fetchData = FoxPlanningApi.getDatasetData(datasetRequest).catch((error) => {
        addNotificationForApiError({ content: t('api_error_fetch_dataset_data'), error });
        return undefined;
      });

      const fetchMetadata = FoxPlanningApi.getDatasetMetadata(datasetRequest).catch((error) => {
        addNotificationForApiError({ content: t('api_error_fetch_dataset_metadata'), error });
        return undefined;
      });

      setFetchingCount((count) => count + 1);

      const [datasetData, datasetMetadata] = await Promise.all([fetchData, fetchMetadata]);

      setFetchingCount((count) => count - 1);

      return { datasetData, datasetMetadata, datasetRequest };
    },
    [addNotificationForApiError, alias, batchId, datasetName, t],
  );

  return { getDataset, isFetchingDataset: fetchingCount > 0 };
};
