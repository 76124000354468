import { AgGridBaseWrapper } from 'src/common/ag-grid/AgGridBaseWrapper';
import {
  AG_OUTPUT_PAGINATION_PAGE_SIZE,
  AG_PIVOT_RESULT_FIELD_SEPARATOR,
} from 'src/pages/plan-manager-page/plan-output-tab/planOutputConfig';
import { GridComponentProps } from 'src/pages/commons/plan-views/DatasetTabsViewer';
import DatasetGridContainer from 'src/pages/commons/plan-views/DatasetGridContainer';
import { useSsrmGridParams } from 'src/pages/commons/data-grids/useSsrmGridParams';
import { useSsrmInputGridParams } from 'src/pages/plan-manager-page/plan-input-tab/components/useSsrmInputGridParams';
import UploadDatasetModal from 'src/pages/plan-manager-page/plan-input-tab/components/UploadDatasetModal';
import UserConfirmModal from 'src/common/UserConfirmModal';

const PlanInputSsrmGrid = ({
  headerText,
  description,
  datasetOptions,
  currentDataset,
  setCurrentDataset,
  setCurrentGridPivotMode,
  currentGridToolPanel,
  setCurrentGridToolPanel,
}: GridComponentProps) => {
  /** ssrm grid common params */
  const {
    gridRef,
    containerRef,
    gridHeight,
    cellSelection,
    isFullScreen,
    resizeColumns,
    onModelUpdated,
    onColumnPivotModeChanged,
    onToolPanelVisibleChanged,
    setIsFullScreen,
    setOnRefreshStaleDataset,
  } = useSsrmGridParams({ currentDataset, setCurrentGridPivotMode, setCurrentGridToolPanel });

  /** plan input grid specific params */
  const {
    serverSideDatasource,
    datasetMetadata,
    mappingSchema,
    columnDefs,
    defaultColDef,
    validHeaders,
    actionButtonGroups,
    rowSelection,
    confirmModalInfo,
    displayDescription,
    currentDatasetLabel,
    isUpdatingDataset,
    isImportModalVisible,
    getRowId,
    setConfirmModalInfo,
    setIsImportModalVisible,
    uploadDatasetMutationMethod,
    handleDatasetSelected,
    UnsavedChangesModal,
  } = useSsrmInputGridParams({
    gridRef,
    currentDataset,
    datasetOptions,
    description,
    setIsFullScreen,
    setOnRefreshStaleDataset,
    setCurrentDataset,
  });

  return (
    <>
      <DatasetGridContainer
        currentDataset={currentDataset}
        onDatasetSelected={handleDatasetSelected}
        datasetOptions={datasetOptions}
        headerText={headerText}
        description={displayDescription}
        actionButtonGroups={actionButtonGroups}
      >
        <div ref={containerRef}>
          <AgGridBaseWrapper
            /** custom props */
            gridRef={gridRef}
            gridHeight={gridHeight}
            isFullScreen={isFullScreen}
            setFullScreen={setIsFullScreen}
            /** ag grid props */
            rowModelType="serverSide"
            serverSideDatasource={serverSideDatasource}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            cellSelection={cellSelection}
            rowSelection={rowSelection}
            gridToolPanel={currentGridToolPanel}
            pagination={true}
            paginationPageSizeSelector={false}
            paginationPageSize={AG_OUTPUT_PAGINATION_PAGE_SIZE}
            serverSidePivotResultFieldSeparator={AG_PIVOT_RESULT_FIELD_SEPARATOR}
            suppressRowVirtualisation={true}
            suppressColumnVirtualisation={true}
            cacheBlockSize={250}
            tooltipShowDelay={1000}
            getRowId={getRowId}
            onModelUpdated={onModelUpdated}
            onPaginationChanged={resizeColumns}
            onCellEditingStopped={resizeColumns}
            onColumnPivotModeChanged={onColumnPivotModeChanged}
            onToolPanelVisibleChanged={onToolPanelVisibleChanged}
          />
        </div>
      </DatasetGridContainer>

      <UploadDatasetModal
        visible={isImportModalVisible}
        currentDataset={currentDataset}
        currentDatasetLabel={currentDatasetLabel}
        isUpdatingDataset={isUpdatingDataset}
        datasetMetadata={datasetMetadata}
        validHeaders={validHeaders}
        mappingSchema={mappingSchema}
        gridRef={gridRef}
        onClose={() => setIsImportModalVisible(false)}
        uploadDatasetMutationMethod={uploadDatasetMutationMethod}
      />

      <UserConfirmModal
        confirmModalInfo={confirmModalInfo}
        setConfirmModalInfo={setConfirmModalInfo}
      />

      {UnsavedChangesModal}
    </>
  );
};

export default PlanInputSsrmGrid;
