import { Button } from '@amzn/awsui-components-react/polaris';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { BiExport, BiImport, BiRefresh } from 'react-icons/bi';
import { FaRegClone, FaRegSave } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { BsArchive, BsFillArchiveFill } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  UploadBatchEditsRequest,
  ValidationStatusEnumEnum,
} from '@amzn/fox-den-cost-planning-lambda';
import { GetRowIdFunc, RowSelectionOptions } from 'ag-grid-community';
import { RiExportFill, RiImportFill } from 'react-icons/ri';
import { uniqueId } from 'lodash';
import {
  DataGridActionButtonGroups,
  OTRAutomatedImportDatasetsWithTrailingSelector,
} from 'src/pages/commons/plan-views/DatasetGridContainer';
import {
  useTriggerComputeFromCPU,
  useTriggerComputeFromMM,
  useTriggerLunaExport,
  useTriggerOtrObAutomatedDatasetImport,
  useTriggerPlanImportBaseline,
} from 'src/pages/commons/compute-helpers/useTriggerPlanCompute';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { SelectChangeEvent } from 'src/common/EventType';
import { SupportedDatasetType, TriggerType } from 'src/utils/planning/planetModel';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import {
  agRowInlineEditsAdd,
  agRowInlineEditsDelete,
  IInlineEditRecord,
  IInlineEditType,
} from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/inputAgGridInlineEditHelper';
import { ConfirmModalInfo } from 'src/common/UserConfirmModal';
import { usePlanPageContext } from 'src/pages/plan-manager-page/PlanManagerPage';
import { useIsPlanPolling } from 'src/pages/commons/compute-helpers/useComputationPollingManager';
import { UTRLargeDatasetNames } from 'src/pages/plan-manager-page/plan-input-tab/components/PlanInputDataGrid';
import { TOPS_DOWN_EDITABLE_OUTPUT_DATASET_TYPES } from 'src/pages/plan-manager-page/plan-output-tab/PlanOutputTab';
import { useAsyncUploadEditsMutation } from 'src/api/mutation/useAsyncUploadEditsMutation';
import { OperationType, useUploadEditsMutation } from 'src/api/mutation/useUploadEditsMutation';
import { buildComputeParams } from 'src/api/mutation/useTriggerComputeMutation';
import { downloadFile, getDatasetErrorInfo } from 'src/utils/errorMessage';
import { useInputAgGridDefaultColDef } from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/useInputAgGridDefaultColDef';
import { useUnsavedChangesModal } from 'src/pages/commons/plan-actions/useUnsavedChangesModal';
import { currentDateTime } from 'src/utils/time';
import { RequestVariables } from 'src/api/api-utils';
import { getLastUpdatedOnOfDataset } from 'src/utils/planning/batchMetadataModel';
import {
  IRowValidationCellData,
  IRowValidationRowWithId,
} from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/inputAgGridRowValidationHelper';
import { DatasetOption } from 'src/pages/commons/plan-views/DatasetTabsViewer';
import { useSsrmInputDataset } from 'src/pages/plan-manager-page/plan-input-tab/components/useSsrmInputDataset';
import { useDatasetExportLink } from 'src/api/query/useDatasetExportLink';

interface UseSsrmInputGridParamsProps {
  gridRef: RefObject<AgGridReact>;
  currentDataset: SupportedDatasetType;
  datasetOptions: DatasetOption[];
  description: ReactNode;
  setIsFullScreen: Dispatch<SetStateAction<boolean>>;
  setOnRefreshStaleDataset: Dispatch<SetStateAction<(() => void) | null>>;
  setCurrentDataset: (currentDataset: SupportedDatasetType) => void;
}

export const useSsrmInputGridParams = ({
  gridRef,
  currentDataset,
  datasetOptions,
  description,
  setIsFullScreen,
  setOnRefreshStaleDataset,
  setCurrentDataset,
}: UseSsrmInputGridParamsProps) => {
  const { t } = useTranslation();
  const { batchId } = useParams();
  const { data: featureFlags } = useFeatureFlags();
  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const {
    serverSideDatasource,
    isFetchingDataset,
    schema,
    mappingSchema,
    columnDefs,
    validationRowTransformer,
    datasetMetadata,
    datasetRowCount,
    datasetRequest,
  } = useSsrmInputDataset(currentDataset);

  const { refetch: getDatasetExportLink } = useDatasetExportLink(datasetRequest);

  const [isUpdatingDataset, setIsUpdatingDataset] = useState(false);
  const [shouldSaveDataset, setShouldSaveDataset] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [confirmModalInfo, setConfirmModalInfo] = useState<ConfirmModalInfo>(null);

  const [inlineEdits, setInlineEdits] = useState<IInlineEditRecord[]>([]);

  const { isHasUnsavedEdits, setIsHasUnsavedEdits, setInProgressAsyncUploadEditsDatasets } =
    usePlanPageContext();

  const { processingComputeTrigger } = useIsPlanPolling(batchMetadata);

  const { triggerOtrObAutomatedDatasetImport } = useTriggerOtrObAutomatedDatasetImport();

  const validHeaders = useMemo(() => schema?.map((item) => item.dimensionName) ?? [], [schema]);

  const isLargeDataset = UTRLargeDatasetNames.has(currentDataset);

  const inlineEditsDisabled =
    isLargeDataset ||
    batchMetadata?.status === 'CLOSED' ||
    processingComputeTrigger === TriggerType.TOPS_DOWN_FORECAST_VOLUME_DATASET_IMPORT ||
    processingComputeTrigger === TriggerType.TOPS_DOWN_FORECAST_BASELINE_CPU_DATASET_IMPORT;

  const { defaultColDef } = useInputAgGridDefaultColDef({
    validationRowTransformer,
    gridRef,
    validHeaders,
    setInlineEdits,
    inlineEditsDisabled,
  });

  const { triggerImportBaseline } = useTriggerPlanImportBaseline();

  const { triggerComputeFromCPU } = useTriggerComputeFromCPU();

  const { triggerComputeFromMM } = useTriggerComputeFromMM();

  const { triggerLunaExport } = useTriggerLunaExport();

  const { mutate: asyncUploadEdits } = useAsyncUploadEditsMutation({
    onSuccess: (data, variables) => {
      setInProgressAsyncUploadEditsDatasets(
        (prev) => new Set([...prev, ...variables.datasetNames] as SupportedDatasetType[]),
      );
    },
  });

  const { mutate: updateNormalDatasetMutation } = useUploadEditsMutation({
    onMutate: () => {
      setIsUpdatingDataset(true);
    },
    onError: () => {
      setIsUpdatingDataset(false);
    },
    onSuccess: () => {
      const onSuccess = () => setInlineEdits([]);
      const onSettled = () => setIsUpdatingDataset(false);

      if (batchMetadata?.batchId) {
        switch (currentDataset) {
          case SupportedDatasetType.TOPS_DOWN_FORECAST_COEFFICIENT:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_KPI_BASELINE:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_CHC:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_Q2G_CPU:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_VOLUME:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_LUNA_OUTPUT:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_MANUAL_BRIDGE:
          case SupportedDatasetType.CF_CONSOLIDATION_INPUT_FC_FIXED:
          case SupportedDatasetType.CF_CONSOLIDATION_INPUT_FC_VARIABLE:
          case SupportedDatasetType.CF_CONSOLIDATION_INPUT_SHIPPED_UNITS:
          case SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_INPUT:
          case SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_NON_DESIGNATED_INPUT:
          case SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_OUTPUT:
            onSuccess();
            onSettled();
            break;
          case SupportedDatasetType.TOPS_DOWN_FORECAST_CPU_FORECAST:
            triggerComputeFromCPU({ batchId: batchMetadata.batchId }, { onSuccess, onSettled });
            break;
          case SupportedDatasetType.TOPS_DOWN_FORECAST_MM_FORECAST:
            triggerComputeFromMM({ batchId: batchMetadata.batchId }, { onSuccess, onSettled });
            break;
          default:
            asyncUploadEdits(
              { batchId: batchMetadata.batchId, datasetNames: [currentDataset] },
              { onSuccess, onSettled },
            );
            break;
        }
      }
    },
  });

  /**
   * Literally does nothing except for changing the lastUpdatedTime of the currentDataset
   * due to bug in BE which doesn't update lastUpdatedTime in UTR_Large_Data_Update_Load
   */
  const { mutate: updateLargeDatasetMutation } = useUploadEditsMutation({
    onMutate: () => {
      setIsUpdatingDataset(true);
    },
    onError: () => {
      setIsUpdatingDataset(false);
    },
    onSuccess: (data, variables) => {
      const onSuccess = () => setInlineEdits([]);
      const onSettled = () => setIsUpdatingDataset(false);

      if (batchMetadata?.batchId) {
        asyncUploadEdits(
          {
            batchId: batchMetadata.batchId ?? '',
            datasetNames: [variables.datasetType],
            parameters: buildComputeParams({
              batchEditsFile: variables.batchEditsFile,
              headers: variables.headers,
              partitionS3VersionMap: variables.partitionS3VersionMap,
              partitionLocator: variables.partitionLocator,
              operation: variables.operation,
            }),
          },
          { onSuccess, onSettled },
        );
      }
    },
  });

  const handleSaveDataset = useCallback(() => {
    if (!datasetMetadata || !batchMetadata || inlineEditsDisabled) {
      setIsUpdatingDataset(false);
      return;
    }

    const batchEdits = inlineEdits.map((item) => {
      switch (item.editType) {
        case IInlineEditType.ADD:
          return { previous: [], current: item.current };
        case IInlineEditType.MODIFY:
          return { previous: item.previous, current: item.current };
        case IInlineEditType.DELETE:
          return { previous: item.previous, current: [] };
      }
    });

    const { partitionS3VersionMap, partitionLocator } = datasetMetadata;

    const lastUpdatedOn = getLastUpdatedOnOfDataset(batchMetadata, currentDataset);

    const inlineEditsUploadArguments: RequestVariables<UploadBatchEditsRequest> = {
      batchId: batchMetadata.batchId ?? '',
      batchEdits,
      headers: validHeaders,
      datasetType: currentDataset,
      lastUpdatedOn,
      partitionS3VersionMap: partitionS3VersionMap ?? null,
      partitionLocator: partitionLocator ?? null,
      operation: OperationType.UPSERT,
    };

    updateNormalDatasetMutation(inlineEditsUploadArguments);
  }, [
    batchMetadata,
    currentDataset,
    datasetMetadata,
    inlineEdits,
    inlineEditsDisabled,
    updateNormalDatasetMutation,
    validHeaders,
  ]);

  useEffect(() => {
    if (shouldSaveDataset) {
      setShouldSaveDataset(false);
      handleSaveDataset();
    }
  }, [handleSaveDataset, shouldSaveDataset]);

  const { UnsavedChangesModal } = useUnsavedChangesModal({
    isHasUnsavedEdits,
    onDiscard: () => setInlineEdits([]),
    onSave: handleSaveDataset,
  });

  const getRowId = useCallback<GetRowIdFunc>((params) => params?.data?.id?.value ?? uniqueId(), []);

  const currentDatasetLabel = datasetOptions.find((item) => item.value === currentDataset)?.label;

  const displayDescription = useMemo(() => {
    const datasetErrorInfo = getDatasetErrorInfo(batchMetadata, currentDataset);

    if (datasetErrorInfo.message) {
      return (
        <Button
          variant="inline-link"
          disabled={!datasetErrorInfo.downloadLink}
          onClick={() =>
            downloadFile(datasetErrorInfo.downloadLink!, featureFlags?.feS3UrlGeneration ?? false)
          }
        >
          {datasetErrorInfo.message}
        </Button>
      );
    }

    const currentDatasetValidationDetails =
      batchMetadata?.datasetStatus?.detailedDatasetStatus?.find(
        (o) => o.datasetName === currentDataset,
      )?.validationDetails;

    const currentDatasetValidationStatus = currentDatasetValidationDetails?.status;

    if (
      currentDatasetValidationStatus &&
      currentDatasetValidationStatus !== ValidationStatusEnumEnum.Success
    ) {
      return (
        <Button
          variant="inline-link"
          disabled={!currentDatasetValidationDetails.messageLocation}
          onClick={() => {
            downloadFile(
              currentDatasetValidationDetails.messageLocation!,
              featureFlags?.feS3UrlGeneration ?? false,
            );
          }}
        >
          {currentDatasetValidationDetails.message}
        </Button>
      );
    }

    return <span>{description}</span>;
  }, [batchMetadata, currentDataset, description, featureFlags?.feS3UrlGeneration]);

  const actionButtonGroups = useMemo(() => {
    const isTopsDownEditableOutputDataset =
      TOPS_DOWN_EDITABLE_OUTPUT_DATASET_TYPES.has(currentDataset);

    const handleClickSave = () => {
      setIsUpdatingDataset(true);
      /** Give time for ag grid to commit any cells currently being edited
       *  and inlineEdits to reflect the new value **/
      setTimeout(() => setShouldSaveDataset(true), 500);
    };

    const handleClickWebbImport = () => {
      if (!batchMetadata?.batchId) return;
      triggerImportBaseline({ batchId: batchMetadata.batchId });
    };

    const handleClickImport = () => {
      const onProceed = () => setIsImportModalVisible(true);

      if (isHasUnsavedEdits) {
        setConfirmModalInfo({
          header: t('discard_changes'),
          message: t('import_unsaved_changes_warning'),
          onProceed,
        });
      } else {
        onProceed();
      }
    };

    const handleClickLunaExport = () => {
      if (!batchMetadata?.batchId) return;
      triggerLunaExport({ batchId: batchMetadata.batchId });
    };

    const handleClickExport = async () => {
      const { data: datasetExportLink } = await getDatasetExportLink();
      const planName = (batchMetadata?.batchName ?? '').replaceAll('.', '_');
      const fileName = `${currentDataset}_${planName}_${currentDateTime()}`;
      downloadFile(datasetExportLink, featureFlags?.feS3UrlGeneration ?? false, {
        fileName,
        generateFreshUrl: false,
      });
    };

    const handleClickRefresh = () => {
      const onProceed = () => {
        if (OTRAutomatedImportDatasetsWithTrailingSelector.has(currentDataset)) {
          triggerOtrObAutomatedDatasetImport({
            batchId: batchMetadata?.batchId ?? '',
            parameters: buildComputeParams({ datasetName: currentDataset }),
          });
        } else {
          gridRef.current?.api.refreshServerSide({ purge: true });
          setInlineEdits([]);
        }
      };

      if (isHasUnsavedEdits) {
        setConfirmModalInfo({
          header: t('discard_changes'),
          message: t('refresh_data_unsaved_changes_warning'),
          onProceed,
        });
      } else {
        onProceed();
      }
    };

    const handleClickAdd = () => {
      const gridApi = gridRef.current?.api;

      if (!gridApi || !validationRowTransformer || validHeaders.length === 0) {
        return;
      }

      const newUniqueId = `${uniqueId()}`;

      const newRow: IRowValidationRowWithId = {
        id: {
          value: newUniqueId,
          original: newUniqueId,
          lastValidationSuccess: true,
          ...(featureFlags?.usingValidationFramework && {
            lastValidationNoWarning: true,
          }),
          validationErrors: [],
        },
      };

      for (const header of validHeaders) {
        const newValue: IRowValidationCellData = {
          value: null,
          original: null,
          lastValidationSuccess: true,
          ...(featureFlags?.usingValidationFramework && {
            lastValidationNoWarning: true,
          }),
          validationErrors: [],
        };

        newRow[header] = newValue;
      }

      const formattedRow = validationRowTransformer(newRow);

      const inlineEditsUpdater = agRowInlineEditsAdd(formattedRow, validHeaders);
      setInlineEdits(inlineEditsUpdater);

      const result = gridApi.applyServerSideTransaction({ add: [formattedRow], addIndex: 0 });
      gridApi.paginationGoToFirstPage();
      gridApi.ensureIndexVisible(0);
      /** workaround to make flashCells work for newly created cells */
      setTimeout(() => gridApi.flashCells({ rowNodes: result?.add }), 300);
    };

    const handleClickClone = () => {
      const gridApi = gridRef.current?.api;

      if (!gridApi || !validationRowTransformer || validHeaders.length === 0) {
        return;
      }

      const selectedRows = gridApi.getSelectedRows();

      if (selectedRows.length === 0) return;

      const rowsToAdd = selectedRows.map((row: IRowValidationRowWithId) => {
        const newUniqueId = `${uniqueId()}`;

        const record: IRowValidationRowWithId = {
          id: {
            value: newUniqueId,
            original: newUniqueId,
            lastValidationSuccess: true,
            ...(featureFlags?.usingValidationFramework && {
              lastValidationNoWarning: true,
            }),
            validationErrors: [],
          },
        };

        for (const [key, cellData] of Object.entries(row)) {
          if (key !== 'id') {
            const newValue: IRowValidationCellData = {
              value: cellData.value,
              original: null,
              lastValidationSuccess: true,
              ...(featureFlags?.usingValidationFramework && {
                lastValidationNoWarning: true,
              }),
              validationErrors: [],
            };

            record[key] = newValue;
          }
        }
        return validationRowTransformer(record);
      });

      for (const row of rowsToAdd) {
        const inlineEditsUpdater = agRowInlineEditsAdd(row, validHeaders);
        setInlineEdits(inlineEditsUpdater);
      }

      const result = gridApi.applyServerSideTransaction({ add: rowsToAdd, addIndex: 0 });
      gridApi.paginationGoToFirstPage();
      gridApi.ensureIndexVisible(0);
      gridApi.deselectAll();
      /** workaround to make flashCells work for newly created cells */
      setTimeout(() => gridApi.flashCells({ rowNodes: result?.add }), 300);
    };

    const handleClickDelete = () => {
      const gridApi = gridRef.current?.api;

      if (!gridApi) return;

      const selectedRows = gridApi.getSelectedRows();

      for (const row of selectedRows) {
        const inlineEditsUpdater = agRowInlineEditsDelete(row, validHeaders);
        setInlineEdits(inlineEditsUpdater);
      }

      gridApi.applyServerSideTransaction({ remove: selectedRows });
    };

    const handleClickBulkDelete = () => {
      if (!batchId) return;

      const onBulkDeleteProceed = () => {
        asyncUploadEdits({
          batchId,
          datasetNames: [currentDataset],
          parameters: {
            DeleteFilterMapParamKey: [JSON.stringify(datasetRequest?.filterModel)],
            operation: ['DELETE'],
          },
        });
      };

      const onDiscardChangesProceed = () =>
        setConfirmModalInfo({
          header: t('bulk_delete_header'),
          message: t('bulk_delete_confirm_message', {
            rowCount: datasetRowCount,
          }),
          onProceed: onBulkDeleteProceed,
        });

      if (isHasUnsavedEdits) {
        setConfirmModalInfo({
          header: t('discard_changes'),
          message: t('bulk_delete_unsaved_changes_warning'),
          onProceed: onDiscardChangesProceed,
        });
      } else {
        onDiscardChangesProceed();
      }
    };

    const handleClickExpand = () => {
      setIsFullScreen(true);
    };

    const isDatasetLoading = isFetchingDataset || isUpdatingDataset;

    const isDatasetEmpty = datasetRowCount === 0;

    const isExceedExportLimit = datasetRowCount && datasetRowCount >= 999999;

    const buttonGroups: DataGridActionButtonGroups = [
      [
        {
          icon: FaRegSave,
          text: t('save'),
          onClick: handleClickSave,
          disabled: isDatasetLoading,
          hide: inlineEditsDisabled,
        },
        {
          icon: RiImportFill,
          text: t('webb_import'),
          onClick: handleClickWebbImport,
          disabled: isDatasetLoading,
          hide: currentDataset !== SupportedDatasetType.TOPS_DOWN_FORECAST_KPI_BASELINE,
        },
        {
          icon: BiImport,
          text: t('import'),
          onClick: handleClickImport,
          disabled: isDatasetLoading,
          hide: isTopsDownEditableOutputDataset,
        },
        {
          icon: RiExportFill,
          text: t('luna_export'),
          onClick: handleClickLunaExport,
          disabled: isDatasetLoading,
          hide: currentDataset !== SupportedDatasetType.TOPS_DOWN_FORECAST_LUNA_OUTPUT,
        },
        {
          icon: BiExport,
          text: t('export'),
          onClick: handleClickExport,
          disabled: isExceedExportLimit ? t('dataset_size_exceed_export_limit') : isDatasetLoading,
          hide: false,
        },
        {
          icon: BiRefresh,
          text: t('refresh'),
          onClick: handleClickRefresh,
          disabled: isDatasetLoading,
          hide: false,
        },
      ],
      [
        {
          icon: MdAdd,
          text: t('add_row'),
          onClick: handleClickAdd,
          disabled: isDatasetLoading,
          hide: isTopsDownEditableOutputDataset || inlineEditsDisabled,
        },
        {
          icon: FaRegClone,
          text: t('clone_rows'),
          onClick: handleClickClone,
          disabled: isDatasetLoading,
          hide: isTopsDownEditableOutputDataset || inlineEditsDisabled,
        },
        {
          icon: BsFillArchiveFill,
          text: t('delete'),
          onClick: handleClickDelete,
          disabled: isDatasetLoading,
          hide: isTopsDownEditableOutputDataset || inlineEditsDisabled,
        },
        {
          icon: BsArchive,
          text: t('bulk_delete'),
          onClick: handleClickBulkDelete,
          disabled: isDatasetLoading || isDatasetEmpty,
          hide: isTopsDownEditableOutputDataset,
        },
      ],
      [
        {
          icon: AiOutlineFullscreen,
          text: t('expand'),
          onClick: handleClickExpand,
          hide: false,
        },
      ],
    ];

    return buttonGroups;
  }, [
    currentDataset,
    isFetchingDataset,
    isUpdatingDataset,
    datasetRowCount,
    t,
    inlineEditsDisabled,
    batchMetadata?.batchId,
    batchMetadata?.batchName,
    triggerImportBaseline,
    isHasUnsavedEdits,
    triggerLunaExport,
    getDatasetExportLink,
    featureFlags?.feS3UrlGeneration,
    featureFlags?.usingValidationFramework,
    triggerOtrObAutomatedDatasetImport,
    gridRef,
    validationRowTransformer,
    validHeaders,
    batchId,
    asyncUploadEdits,
    datasetRequest?.filterModel,
    setIsFullScreen,
  ]);

  const rowSelection = useMemo<RowSelectionOptions | undefined>(
    () => (inlineEditsDisabled ? undefined : { mode: 'multiRow', headerCheckbox: false }),
    [inlineEditsDisabled],
  );

  const handleDatasetSelected = (e: SelectChangeEvent) => {
    const newDataset = e.detail.selectedOption.value as SupportedDatasetType;

    if (newDataset === currentDataset) return;

    const onProceed = () => setCurrentDataset(newDataset);

    if (isHasUnsavedEdits) {
      setConfirmModalInfo({
        header: t('discard_changes'),
        message: t('change_tables_unsaved_changes_warning'),
        onProceed,
      });
    } else {
      onProceed();
    }
  };

  const uploadDatasetMutationMethod = isLargeDataset
    ? updateLargeDatasetMutation
    : updateNormalDatasetMutation;

  /** sync inline edits status to plan page context */
  useEffect(() => {
    setIsHasUnsavedEdits(inlineEdits.length > 0);
  }, [inlineEdits.length, setIsHasUnsavedEdits]);

  /** clear inline edits when the dataset is being refreshed */
  useEffect(() => {
    setOnRefreshStaleDataset(() => () => setInlineEdits([]));
  }, [setOnRefreshStaleDataset]);

  return {
    serverSideDatasource,
    datasetMetadata,
    mappingSchema,
    columnDefs,
    defaultColDef,
    validHeaders,
    actionButtonGroups,
    rowSelection,
    confirmModalInfo,
    displayDescription,
    currentDatasetLabel,
    isUpdatingDataset,
    isImportModalVisible,
    getRowId,
    setConfirmModalInfo,
    setIsImportModalVisible,
    uploadDatasetMutationMethod,
    handleDatasetSelected,
    UnsavedChangesModal,
  };
};
