import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router';

const useOnBeforeUnload = (blockUnload: boolean) => {
  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    };

    if (blockUnload) window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [blockUnload]);
};

interface UseUnsavedChangesModalProps {
  isHasUnsavedEdits: boolean;
  onSave: () => void;
  onDiscard: () => void;
}

export const useUnsavedChangesModal = ({
  isHasUnsavedEdits,
  onSave,
  onDiscard,
}: UseUnsavedChangesModalProps) => {
  const { t } = useTranslation();
  const blocker = useBlocker(isHasUnsavedEdits);
  const [isShowModal, setIsShowModal] = useState(false);

  /** show warning when user try to leave page [block unload event] */
  useOnBeforeUnload(isHasUnsavedEdits);

  /** show warning when user try to leave page [block react-router] */
  useEffect(() => {
    if (blocker.state === 'blocked') setIsShowModal(true);
  }, [blocker]);

  /** handle cancel button */
  const handleCancel = () => {
    blocker.reset?.();
    setIsShowModal(false);
  };

  /** handle discard button */
  const handleDiscard = () => {
    onDiscard();
    setTimeout(() => blocker.proceed?.(), 0);
    setIsShowModal(false);
  };

  /** handle save button */
  const handleSave = () => {
    onSave();
    setTimeout(() => blocker.proceed?.(), 0);
    setIsShowModal(false);
  };

  return {
    UnsavedChangesModal: (
      <Modal
        visible={isShowModal}
        onDismiss={handleCancel}
        header={t('unsaved_changes_modal_header')}
        size="small"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={handleCancel}>
                {t('cancel')}
              </Button>
              <Button variant="normal" onClick={handleDiscard}>
                {t('discard')}
              </Button>
              <Button variant="primary" onClick={handleSave}>
                {t('save')}
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {t('unsaved_changes_modal_message')}
      </Modal>
    ),
  };
};
