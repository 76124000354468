import {
  AppLayout,
  Form,
  Header,
  SpaceBetween,
  Button,
  Box,
} from '@amzn/awsui-components-react/polaris';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useAsyncUploadEditsMutation } from 'src/api/mutation/useAsyncUploadEditsMutation';
import { useCreatePlanMutation } from 'src/api/mutation/useCreateBatchMutation';
import { GlobalNotifications } from 'src/common/GlobalNotifications';
import { Breadcrumbs } from 'src/common/Breadcrumbs';
import { appLayoutHeaderSelector } from 'src/common/NavBar';
import {
  useTriggerTopsDownForecastCreatePlan,
  useTriggerFixedCostConsolidationCreatePlan,
} from 'src/pages/commons/compute-helpers/useTriggerPlanCompute';
import { CreatePlanDetailsPanel } from 'src/pages/create-plan-page/components/CreatePlanDetailsPanel';
import { CreatePlanGeneralPanel } from 'src/pages/create-plan-page/components/CreatePlanGeneralPanel';
import {
  convertStartEndDateForPlanCreate,
  getDefaultCurrencyScenario,
} from 'src/pages/create-plan-page/createPlanBusinessLogic';
import { useCreatePlanFormManager } from 'src/pages/create-plan-page/hooks/useCreatePlanFormManager';
import { PathNames, getPlanDetailsRoute } from 'src/routes';
import { BatchStatus, PlanTypeId } from 'src/utils/planning/planetModel';

const CreatePlanBody = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();

  const { formContent, onUpdateFormField, canCreatePlan } = useCreatePlanFormManager(state);

  const { mutate: asyncUploadEdits } = useAsyncUploadEditsMutation();

  const { triggerTopsDownForecastCreatePlan } = useTriggerTopsDownForecastCreatePlan();

  const { triggerFixedCostConsolidationCreatePlan } = useTriggerFixedCostConsolidationCreatePlan();

  /* Providing onSuccess when mutation is created makes it happen globally
  regardless of whether the user changes pages during the mutation */
  const { mutate: createPlanMutation } = useCreatePlanMutation({
    meta: {
      inProgressNotificationText: t('api_in_progress_create_plan'),
      errorNotificationText: t('api_error_create_plan'),
      successNotificationText: t('api_success_create_plan'),
    },
    onSuccess: (data) => {
      if (data.batchId && data.costType) {
        if (data.costType === PlanTypeId.TOPS_DOWN_FORECAST) {
          triggerTopsDownForecastCreatePlan({ batchId: data.batchId });
        } else if (data.costType === PlanTypeId.FIXED_COST_CONSOLIDATION) {
          triggerFixedCostConsolidationCreatePlan({ batchId: data.batchId });
        } else if (data.costType === PlanTypeId.CF_CONSOLIDATION) {
          // Do nothing
        } else if (data.costType === PlanTypeId.UTR_PRODUCTIVITY) {
          // Do nothing
        } else {
          asyncUploadEdits({
            batchId: data.batchId,
            datasetNames: [],
          });
        }
      }
    },
  });

  const onClickCreatePlan = () => {
    setIsLoading(true);
    if (!canCreatePlan) {
      setIsLoading(false);
      return;
    }

    const planDateDetails = convertStartEndDateForPlanCreate(formContent);

    if (!planDateDetails?.duration || !planDateDetails.startYear) {
      setIsLoading(false);
      return;
    }

    const [startYear, startMonth] = formContent.startDate.split('-');
    const planStartMonth = `${startYear}-${startMonth}`;

    createPlanMutation(
      {
        // no longer needed to send from FE for PLANet
        batchName: '',
        // no longer needed for PLANet
        batchNameSuffix: '',
        businessGroup: formContent.businessGroup!.value,
        region: formContent.region!.value,
        countries: formContent.countries.map((countryOption) => countryOption.value),
        subGroup: formContent.subGroup!.value,
        costType: formContent.planType!.value,
        planCycle: formContent.planCycle!.value,
        periodType: formContent.periodType!.value,
        startDate: planDateDetails.startDate,
        endDate: planDateDetails.endDate,
        currency: formContent.currency!.value,
        currencyScenario: getDefaultCurrencyScenario(),
        slotDescription: formContent.slotDescription,
        duration: planDateDetails.duration.toString(),
        startYear: planDateDetails.startYear,
        startMonth: planStartMonth,
        status: BatchStatus.OPEN,
        visibility: 'VISIBLE', // Hard coded since other options are not used
      },
      {
        onSuccess: (data) => {
          navigate(getPlanDetailsRoute(data.batchId!));
        },
        onError() {
          setIsLoading(false);
        },
      },
    );
  };

  const onClickCancel = () => {
    navigate(PathNames.LIST_PLANS_PAGE);
  };

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={onClickCancel}>
            {t('cancel')}
          </Button>
          <Button
            data-testid="create"
            variant="primary"
            disabled={!canCreatePlan || isLoading}
            onClick={onClickCreatePlan}
          >
            {t('create_plan_button')}
          </Button>
        </SpaceBetween>
      }
      header={<Header variant="h1">{t('create_plan_header')}</Header>}
      errorIconAriaLabel="Error"
    >
      <CreatePlanGeneralPanel formContent={formContent} onUpdateFormField={onUpdateFormField} />
      <Box margin={{ top: 'l' }}>
        <CreatePlanDetailsPanel formContent={formContent} onUpdateFormField={onUpdateFormField} />
      </Box>
    </Form>
  );
};

const CreatePlanPage = (): JSX.Element => (
  <AppLayout
    content={<CreatePlanBody />}
    toolsHide={true}
    navigationHide={true}
    breadcrumbs={<Breadcrumbs />}
    notifications={<GlobalNotifications />}
    stickyNotifications={true}
    contentType="form"
    tools={<div />}
    disableContentPaddings={false}
    headerSelector={appLayoutHeaderSelector}
  />
);

export default CreatePlanPage;
