import {
  Box,
  FileDropzone,
  FileInput,
  FileTokenGroup,
  FormField,
} from '@amzn/awsui-components-react/polaris';
import { useTranslation } from 'react-i18next';
import { RequiredLabel } from 'src/common/RequiredLabel';
import { FileDropzoneChangeEvent, FileInputChangeEvent } from 'src/common/EventType';

interface FileUploadFormProps {
  label: string;
  constraint: string;
  description: string;
  accept: string;
  file: File | null;
  onChange: (file: File | null) => void;
}

const FileUploadForm = ({
  label,
  constraint,
  description,
  accept,
  file,
  onChange,
}: FileUploadFormProps) => {
  const { t } = useTranslation();

  const handleFileChange = (e: FileInputChangeEvent | FileDropzoneChangeEvent) => {
    const fileTypeAccepted = accept.match(e.detail.value[0].type);
    const fileExtensionAccepted = accept.match(e.detail.value[0].name.split('.').slice(-1)[0]);
    if (!fileTypeAccepted && !fileExtensionAccepted) return;
    onChange(e.detail.value[0]);
  };

  const handleFileDismiss = () => {
    onChange(null);
  };

  return (
    <>
      <FormField
        label={<RequiredLabel text={label} />}
        description={description}
        constraintText={constraint}
      >
        <FileInput accept={accept} onChange={handleFileChange} value={file ? [file] : []}>
          {t('choose_file')}
        </FileInput>
      </FormField>

      <Box margin={{ top: 's' }}>
        <FileDropzone onChange={handleFileChange}>{t('drop_file_label')}</FileDropzone>
      </Box>

      {file ? (
        <Box margin={{ top: 'm' }}>
          <FileTokenGroup
            items={[{ file }]}
            onDismiss={handleFileDismiss}
            showFileLastModified
            showFileSize
          />
        </Box>
      ) : null}
    </>
  );
};

export default FileUploadForm;
