import { Button } from '@amzn/awsui-components-react/polaris';
import {
  DetailedDatasetStatus,
  DatasetStatusEnumEnum,
  ValidationStatusEnumEnum,
} from '@amzn/fox-den-cost-planning-lambda';
import { useIsMutating } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import UserConfirmModal, { ConfirmModalInfo } from 'src/common/UserConfirmModal';
import { useNotificationContext } from 'src/hooks/useNotificationContext';
import { useIsPlanPolling } from 'src/pages/commons/compute-helpers/useComputationPollingManager';
import { useTriggerPlanFullCompute } from 'src/pages/commons/compute-helpers/useTriggerPlanCompute';
import { usePlanPageContext } from 'src/pages/plan-manager-page/PlanManagerPage';
import { downloadFile } from 'src/utils/errorMessage';
import {
  DATASET_TYPE_LABEL_MAP,
  PlanningCycleId,
  PlanTypeId,
  SupportedDatasetType,
} from 'src/utils/planning/planetModel';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';

interface IComputeButtonProps {
  batchId: string | undefined;
}

export const ComputeButton = ({ batchId }: IComputeButtonProps) => {
  const { t } = useTranslation();
  const { data: featureFlags } = useFeatureFlags();

  const { addNotificationWithErrorModal, addNotification } =
    useNotificationContext<DetailedDatasetStatus>();

  const { isHasUnsavedEdits } = usePlanPageContext();

  const isMutating = useIsMutating();

  const { data: batchMetadata, refetch } = useBatchMetadata({ batchId });

  const { isPlanPolling } = useIsPlanPolling(batchMetadata);

  const [confirmModalInfo, setConfirmModalInfo] =
    useState<ConfirmModalInfo<DetailedDatasetStatus>>(null);

  const { triggerFullCompute, isSendingComputeRequest } = useTriggerPlanFullCompute(
    batchMetadata?.costType,
    batchMetadata?.periodType,
    {
      onSuccess: () => {
        if (batchMetadata?.estimateComputationTime) {
          addNotification({
            type: 'success',
            content: t('estimated_computation_time_message', {
              estimateComputationTime: (
                batchMetadata.estimateComputationTime /
                (1000 * 60)
              ).toFixed(1),
            }),
          });
        }
      },
    },
  );

  const OTRAutomatedImportDatasetsRequiringDateRangeForCompute: Set<SupportedDatasetType> = new Set(
    [
      SupportedDatasetType.PLANET_OTR_OB_LDT_ACTUALS,
      SupportedDatasetType.PLANET_OTR_OB_SPEED_ACTUALS,
    ],
  );

  const onClickCompute = async () => {
    /** fetch latest batch dataset validation status */
    const { data: latestBatchMetadata } = await refetch();

    if (!latestBatchMetadata?.batchId) return;

    const planType = latestBatchMetadata?.costType;

    if (planType === PlanTypeId.OTR_SUPPLY_TYPE) {
      for (const dataset of OTRAutomatedImportDatasetsRequiringDateRangeForCompute) {
        const dateRangeKey = `${dataset}_dateRange`;
        if (latestBatchMetadata?.moduleSpecificMetadata?.[dateRangeKey] === undefined) {
          const datasetLabel = DATASET_TYPE_LABEL_MAP[dataset];
          addNotificationWithErrorModal({
            content: t('compute_plan_start_error_notification'),
            errorModal: {
              message: t(`${datasetLabel} has no date range selected.`),
            },
          });
          return;
        }
      }
    }

    const { batchId } = latestBatchMetadata;

    if (latestBatchMetadata?.datasetStatus) {
      const unsuccessfulStatusDatasets =
        latestBatchMetadata?.datasetStatus?.detailedDatasetStatus?.filter(
          (o) =>
            (o.status && o.status !== DatasetStatusEnumEnum.Success) ||
            (o.validationDetails &&
              o.validationDetails.status === ValidationStatusEnumEnum.Failure),
        );

      /** block compute and show error if any dataset not successfully validated */
      if (unsuccessfulStatusDatasets?.length) {
        addNotificationWithErrorModal({
          content: t('compute_plan_start_error_notification'),
          errorModal: {
            message: t('compute_plan_start_error_modal_message'),
            table: {
              items: unsuccessfulStatusDatasets,
              columnDefinitions: [
                {
                  id: 'datasetName',
                  header: t('dataset_name_header'),
                  cell: (item) => DATASET_TYPE_LABEL_MAP[item.datasetName as SupportedDatasetType],
                },
                {
                  id: 'datasetStatus',
                  header: t('dataset_status_header'),
                  cell: (item) => item.status,
                },
                {
                  id: 'datasetDetails',
                  header: t('dataset_details_header'),
                  cell: (item) => item.detail || '-',
                },
                {
                  id: 'validationStatus',
                  header: t('validation_status_header'),
                  cell: (item) => item.validationDetails?.status,
                },
                {
                  id: 'validationMessage',
                  header: t('validation_message_header'),
                  cell: (item) => item.validationDetails?.message || '-',
                },
                {
                  id: 'download',
                  header: t('download'),
                  minWidth: 120,
                  cell: (item) => (
                    <Button
                      onClick={() =>
                        downloadFile(
                          item.validationDetails!.messageLocation!,
                          featureFlags?.feS3UrlGeneration ?? false,
                        )
                      }
                      variant="inline-link"
                    >
                      {t('download')}
                    </Button>
                  ),
                },
              ],
            },
          },
        });
        return;
      }

      const unsuccessfulValidationStatusDatasets =
        latestBatchMetadata?.datasetStatus?.detailedDatasetStatus?.filter(
          (o) =>
            o.validationDetails && o.validationDetails.status !== ValidationStatusEnumEnum.Success,
        );

      /** confirm with user if any dataset has validation warning */
      if (unsuccessfulValidationStatusDatasets?.length) {
        setConfirmModalInfo({
          onProceed: () => {
            triggerFullCompute({ batchId });
          },
          header: t('user_confirm_modal_header'),
          message: t('compute_plan_start_warning_modal_message'),
          table: {
            items: unsuccessfulValidationStatusDatasets,
            columnDefinitions: [
              {
                id: 'datasetName',
                header: t('dataset_name_header'),
                cell: (item) => DATASET_TYPE_LABEL_MAP[item.datasetName as SupportedDatasetType],
              },
              {
                id: 'message',
                header: t('message_header'),
                cell: (item) => item.validationDetails?.message,
              },
              {
                id: 'validationStatus',
                header: t('validation_status_header'),
                cell: (item) => item.validationDetails?.status,
              },
              {
                id: 'download',
                header: t('download'),
                minWidth: 120,
                cell: (item) => (
                  <Button
                    onClick={() =>
                      downloadFile(
                        item.validationDetails!.messageLocation!,
                        featureFlags?.feS3UrlGeneration ?? false,
                      )
                    }
                    variant="inline-link"
                  >
                    {t('download')}
                  </Button>
                ),
              },
            ],
          },
        });
        return;
      }
    }

    triggerFullCompute({ batchId });
  };

  const isPlanClosed = batchMetadata?.status === 'CLOSED';

  const cfConsolidationNeedsTag =
    batchMetadata?.costType === PlanTypeId.CF_CONSOLIDATION &&
    batchMetadata?.planCycle !== PlanningCycleId.CycleActuals &&
    !batchMetadata?.planTag;

  const computeDisabledReason = useMemo(() => {
    if (!batchMetadata) return t('compute_button_block_no_plan');
    if (isPlanPolling) return t('compute_button_block_is_polling');
    if (isSendingComputeRequest) return t('compute_button_block_is_polling');
    if (isMutating) return t('compute_button_block_is_polling');
    if (isPlanClosed) return t('compute_button_block_plan_closed');
    if (isHasUnsavedEdits) return t('compute_button_block_plan_unsaved_edits');
    if (cfConsolidationNeedsTag) return t('compute_button_block_cf_consolidation_needs_tag');
    return undefined;
  }, [
    batchMetadata,
    isHasUnsavedEdits,
    isMutating,
    isPlanClosed,
    isPlanPolling,
    isSendingComputeRequest,
    cfConsolidationNeedsTag,
    t,
  ]);

  return (
    <>
      <Button
        data-testid="plan-compute-button"
        disabled={!!computeDisabledReason}
        disabledReason={computeDisabledReason}
        onClick={onClickCompute}
        variant="primary"
      >
        {isPlanPolling ? t('compute_plan_button_in_progress') : t('compute_plan_button')}
      </Button>

      <UserConfirmModal
        confirmModalInfo={confirmModalInfo}
        setConfirmModalInfo={setConfirmModalInfo}
      />
    </>
  );
};
