import { GetDatasetMetadataResponse } from '@amzn/fox-den-cost-planning-lambda';
import { ColDef, ProcessHeaderForExportParams } from 'ag-grid-enterprise';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { FeatureFlagsResult } from 'src/api/query/useFeatureFlags';
import {
  getMappingSchemaForDataset,
  getSchemaForDataset,
  IBatchMetadataParsed,
  IMappingSchemaColumnItem,
  ITableDataSchemaColumnItem,
} from 'src/utils/planning/batchMetadataModel';
import { PlanTypeId, SupportedDatasetType } from 'src/utils/planning/planetModel';

interface GetDatasetSchemaParams {
  batchMetadata: IBatchMetadataParsed | undefined;
  currentDataset: SupportedDatasetType;
}

interface GetColumnDefsParams {
  planType: PlanTypeId | undefined;
  datasetMetadata?: GetDatasetMetadataResponse | undefined;
  schema: ITableDataSchemaColumnItem[] | undefined;
  mappingSchema: IMappingSchemaColumnItem[] | undefined;
  featureFlags: FeatureFlagsResult;
  isSsrmDataGrid: boolean;
}

// Header words that have a hardcoded capitalization style
const specialTokens: Record<string, string> = {
  id: 'ID',
  isd: 'ISD',
  su: 'SU',
  re: 'RE',
  car: 'CAR',
  rou: 'ROU',
  aro: 'ARO',
  gl: 'GL',
  sqft: 'sqft',
  capex: 'CapEx',
  ctl: 'CTL',
  npv: 'NPV',
  coa: 'COA',
  tco: 'TCO',
  or: 'or',
  uid: 'UID',
  lsa: 'LSA',
  oem: 'OEM',
  usd: 'USD',
};

const convertFieldToHeader = (field: string) =>
  field
    .toLowerCase()
    .split('_')
    .map((token) => {
      if (token in specialTokens) {
        return specialTokens[token];
      }
      return capitalize(token);
    })
    .join(' ');

const numberColumnFilterSortFunction = (a: string, b: string) => parseFloat(a) - parseFloat(b);

export const getColumnDefs = ({
  planType,
  datasetMetadata,
  schema,
  mappingSchema,
  featureFlags,
  isSsrmDataGrid,
}: GetColumnDefsParams) => {
  if (!schema) return undefined;

  const enableOrderMapping = mappingSchema && mappingSchema.length === schema.length;

  return schema
    .map((item, index) => {
      const mappingItem = mappingSchema?.find((m) => m.originalName === item.dimensionName);

      const field = item.dimensionName;

      const headerName = convertFieldToHeader(mappingItem?.displayName || item.dimensionName);

      const hide = mappingItem?.hide;

      const isNumberColumn = item?.dataType === 'NUMBER';

      const displayOrder = enableOrderMapping
        ? mappingSchema.findIndex((m) => m.originalName === item.dimensionName)
        : index;

      return { field, headerName, hide, isNumberColumn, displayOrder };
    })
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map(({ field, headerName, hide, isNumberColumn }) => {
      const enableFilterParams = datasetMetadata?.filterParams?.[field]?.length;
      const enableNumberFormatter = isNumberColumn;
      const disableInferringDataTypes =
        planType === PlanTypeId.FIXED_COST_CONSOLIDATION ||
        featureFlags?.feDisableAgGridInferringDataTypes;

      const sortMethod = isNumberColumn ? numberColumnFilterSortFunction : undefined;

      const colDef: ColDef = {
        field,
        headerName,
        hide,
        enablePivot: !isSsrmDataGrid,
        enableRowGroup: !isSsrmDataGrid,
        enableValue: !isSsrmDataGrid,

        ...(enableFilterParams
          ? {
              filter: 'agSetColumnFilter',
              filterParams: { values: datasetMetadata?.filterParams?.[field].sort(sortMethod) },
            }
          : { filter: !isSsrmDataGrid }),

        ...(enableNumberFormatter
          ? {
              valueFormatter: (params) => {
                /** for invalid numbers, keep the original text */
                if (
                  params.value === null ||
                  params.value === undefined ||
                  params.value === '' ||
                  isNaN(params.value)
                ) {
                  return params.value;
                }

                /** do formatting for valid numbers */
                const num = Number(params.value);
                const schemaItem = schema.find((s) => s.dimensionName === field);
                const formatOptions: Intl.NumberFormatOptions = {
                  maximumFractionDigits: schemaItem?.fractionDigits ?? 20,
                  useGrouping: true,
                };
                return new Intl.NumberFormat('en-US', formatOptions).format(num);
              },
            }
          : {}),

        ...(disableInferringDataTypes ? { cellDataType: false } : {}),
      };

      return colDef;
    });
};

export const useDatasetSchema = ({ batchMetadata, currentDataset }: GetDatasetSchemaParams) => ({
  schema: useMemo(
    () => getSchemaForDataset(currentDataset, batchMetadata),
    [batchMetadata, currentDataset],
  ),
  mappingSchema: useMemo(
    () => getMappingSchemaForDataset(currentDataset, batchMetadata),
    [batchMetadata, currentDataset],
  ),
});

export const useColumnDefs = ({
  planType,
  datasetMetadata,
  schema,
  mappingSchema,
  featureFlags,
  isSsrmDataGrid,
}: GetColumnDefsParams) => ({
  columnDefs: useMemo(
    () =>
      getColumnDefs({
        planType,
        datasetMetadata,
        schema,
        mappingSchema,
        featureFlags,
        isSsrmDataGrid,
      }),
    [planType, datasetMetadata, schema, mappingSchema, featureFlags, isSsrmDataGrid],
  ),
});

/** get ag-grid export processHeaderCallback */
export const getExportProcessHeaderCallback =
  (mappingSchema: IMappingSchemaColumnItem[] | undefined) =>
  (params: ProcessHeaderForExportParams) => {
    const field = params?.column?.getColDef?.().field;
    if (field && mappingSchema) {
      const mappingConfig = mappingSchema.find((config) => config.originalName === field);
      return mappingConfig?.displayName || field;
    }
    return field ?? '';
  };
